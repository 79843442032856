import $ from "jquery";
import { Button, Popover, Tooltip } from "bootstrap";

import $$ from "./script/utils/double-dollar";
import Multilang from "./script/utils/multilang";
import ActionUrls from "./script/utils/action-urls";

import "./script/http/set-axios-defaults";
import Site from "./script/site";
import Forms from "./script/utils/forms";
import StyleGuide from "./script/style-guide";
import BasketCheckout from "./script/basket-checkout";
import BasketInformation from "./script/basket-information";
import Impersonation from "./script/impersonation";
import Import from "./script/import";
import InspirationList from "./script/inspiration-list";
import CollectionList from "./script/collection-list";
import ProductList from "./script/product-list";
import Product from "./script/product";
import OrderList from "./script/order-list";
import NewsList from "./script/news-list";
import VideoObserver from "./script/video-observer";
import Faq from "./script/faq";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";

// dynamically load vue components
import "./components/vue-component-loader";

import "./script/search-autocomplete";
import "./script/components/file-upload";
import "./script/components/navigate-back";
import "./script/components/update-panel";
import "./script/components/store-finder-launcher";
import "./script/utils/scroll-to-handler";

import "./script/store-finder";

/* this declaration in /clientapp/types/static.d.ts isn't picked up, so putting it here */
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

class App {
  static init() {
    console.log("🚀 App.init running");
    const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
    natchGtm.processPage();

    // ReSharper disable once UnknownCssClass
    $(".hide.onload").hide().removeClass("hide").removeClass("onload");

    // Prevent caching of dropdownlist when refreshing
    $("select").attr("autocomplete", "off");

    // initialize Bootstrap components
    $$(`[data-bs-toggle="button"]`, (el) => new Button(el));
    $$(`[data-bs-toggle="popover"]`, (el) => new Popover(el));
    $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));

    Multilang.load();
    ActionUrls.load();

    Site.init();
    Forms.init();
    StyleGuide.init();

    BasketCheckout.init();
    BasketInformation.init();
    Impersonation.init();
    Import.init();
    InspirationList.init();
    CollectionList.init();
    ProductList.init();
    Product.init();
    OrderList.init();
    NewsList.init();
    Faq.init();
    VideoObserver.init();
  }
}

App.init();
