/*
let json = {
  type: "https://localhost:5001/problems/add-to-basket.quantity-lt-moq",
  title: "Aantal < MOQ",
  status: 422,
  detail: "De hoeveelheid is minder dan de minimale bestelhoeveelheid (=10)",
  traceId: "00-c81f1d721d5e5a4c88d90d004a81734c-d23bc30765393245-00",
  errors: {},
};
*/

export type HttpProblemDetails = {
  type?: string;
  title?: string;
  status: number;
  detail: string;
  traceId?: string;
  errors?: object;
};

function isHttpProblemDetails(x: any): x is HttpProblemDetails {
  if (!!x && "type" in x && "title" in x && "status" in x && "detail" in x) {
    return true;
  }
  return false;
}

export function asHttpProblemDetails(o: any, defaultText: string) {
  if (isHttpProblemDetails(o)) {
    return o;
  } else {
    return { type: "", title: "", status: 500, detail: defaultText };
  }
}

export function parse(json: string): HttpProblemDetails {
  try {
    let o = JSON.parse(json);
    return asHttpProblemDetails(o, json);
  } catch (error) {
    console.error(error);
    return { type: "", title: "", status: 500, detail: json };
  }
}
