import $ from "jquery";
import axios from "axios";
import Forms from "../utils/forms";

export class UpdatePanel {
  constructor() {
    const $body = $("body");

    $body.on("click", ".js-update-panel-on-click", (e) => {
      // when used on a form submit button, the downside is that the form is not actually submitted
      // as a GET is performed and no JQuery validation is triggered
      const $target = $(e.currentTarget);
      const href = $target.attr("href") ?? UpdatePanel.getFormAndUrl($target)[1];
      if (!href) {
        return;
      }
      const selector = $target.data("update-panel-selector");
      const pushHistory = !!$target.data("update-panel-push-history");
      e.preventDefault();
      e.stopPropagation();
      this.loadPage(href, selector, pushHistory);
    });

    $body.on("click", ".js-update-panel-on-submit", (e) => {
      // set props on form to be handled when form is submitted, ie. a GET URL
      // this should trigger JQuery validation
      const $clicked = $(e.currentTarget);
      const result = UpdatePanel.getFormAndUrl($clicked);
      const $form = result[0];
      const href = result[1];
      if (!!href) {
        $form.data("update-panel-href", href);
        const selector = $clicked.data("update-panel-selector");
        $form.data("update-panel-selector", selector);
        const pushHistory = !!$clicked.data("update-panel-push-history");
        $form.data("update-panel-push-history", pushHistory);
      }
    });

    $body.on("submit", "form", (e) => {
      const $form = $(e.target);
      const href = $form.data("update-panel-href");
      if (!href) {
        // proceed with normal submit
        // this is only triggered for forms with submit buttons
        // that have "js-update-panel-on-submit" class
        return;
      }
      const selector = $form.data("update-panel-selector");
      const pushHistory = !!$form.data("update-panel-push-history");
      e.preventDefault();
      e.stopPropagation();
      $form.data("update-panel-href", "");
      $form.data("update-panel-selector", "");
      $form.data("update-panel-push-history", "");
      this.loadPage(href, selector, pushHistory);
    });
  }

  private static getFormAndUrl($clicked: JQuery<HTMLElement>): Readonly<[JQuery<HTMLElement>, string?]> {
    const $form = $clicked.parents("form");
    if ($form.length === 0) {
      return [$form, undefined];
    }
    const action = $clicked.attr("formaction") ?? $form.prop("action");
    const queryString = $form.serialize();
    return [$form, `${action}/?${queryString}`];
  }

  private loadPage(href: string, selector: string, push: boolean) {
    console.debug(`updating '${selector}' with content from ${href}`);
    axios.get(href).then((response) => {
      const html = response.data as string;
      const $html = $("<div/>").append(html);
      const $selector = $(selector);
      $selector.fadeOut({
        duration: "fast",
        done: () => {
          this.replaceOnPage($html, selector);
          $selector.fadeIn({
            duration: "fast",
          });
          console.debug(`updated '${selector}' with content from ${href}`);
          Forms.revalidateForms();
          Forms.resetSubmitButtons();
        },
      });
      if (push) {
        // add to navigation history
        history.pushState(null, document.title, href);
      }
    });
  }

  private replaceOnPage = ($html: JQuery<HTMLElement>, selector: string) => {
    const $newResults = $html.find(selector);
    $(selector).html($newResults.html() ?? "");

    // now do same for toasts
    $(".nt-toaster").html($html.find(".nt-toaster").html());
    // and breadcrumbs
    $(".nt-breadcrumb").html($html.find(".nt-breadcrumb").html());
  };
}

new UpdatePanel();
