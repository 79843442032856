import $ from "jquery";
import axios, { AxiosResponse } from "axios";
import Multilang from "./utils/multilang";
import * as Toaster from "./utils/toaster";
import MiniBasketData from "../composables/mini-basket-data";
import { BasketTotalsData } from "../composables/basket-totals-data";
import Product from "./product";
import { DeleteBasketLineResponse, DeleteBasketResponse, UpdateBasketLineResponse } from "./models/basket";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";

import "bootbox";

export default class BasketCheckout {
  static quantitySelector = ".js-basket-line--quantity";
  static batchCodeSelector = ".js-basket-line--batch-code";
  static remarkSelector = ".js-basket-line--remark";
  static freeRollQuantitySelector = ".js-basket-line--free-roll-quantity";
  static freeCartonQuantitySelector = ".js-basket-line--free-carton-quantity";
  static handleUpdateBasketLineTimerId: number | null = null;

  static init() {
    BasketCheckout.initDeleteBasket();
    BasketCheckout.initUpdateBasketLine();
    BasketCheckout.initDeleteBasketLine();
    BasketCheckout.initFreeQuoteItems();
    BasketCheckout.initPager();
  }

  private static initUpdateBasketLine() {
    // called when quantity box value, batch number selection, remark value, or discount value changes
    $(
      BasketCheckout.quantitySelector +
        ", " +
        BasketCheckout.batchCodeSelector +
        ", " +
        BasketCheckout.remarkSelector +
        ", " +
        BasketCheckout.freeRollQuantitySelector +
        ", " +
        BasketCheckout.freeCartonQuantitySelector
    ).on("change", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      // Debouncing
      if (BasketCheckout.handleUpdateBasketLineTimerId != null) {
        clearTimeout(BasketCheckout.handleUpdateBasketLineTimerId);
        BasketCheckout.handleUpdateBasketLineTimerId = null;
      }

      BasketCheckout.handleUpdateBasketLineTimerId = setTimeout(() => {
        BasketCheckout.handleUpdateBasketLine(e);
        BasketCheckout.handleUpdateBasketLineTimerId = null;
        $(e.currentTarget).removeClass("js-dirty");
      }, 1000);
    });
  }

  private static handleUpdateBasketLine(e: JQueryEventObject) {
    const $currentField = $(e.currentTarget);
    const $basketLine = $currentField.closest(".js-basket-line");
    const productId = $basketLine.data("pid") as number;
    const customerProductId = $basketLine.data("cpid") as number;
    const groupGuid = $basketLine.data("gg") as string;

    const $quantity = $basketLine.find(BasketCheckout.quantitySelector);
    const $batchCode = $basketLine.find(BasketCheckout.batchCodeSelector);
    const $remark = $basketLine.find(BasketCheckout.remarkSelector);
    const $freeRollQuantity = $basketLine.find(BasketCheckout.freeRollQuantitySelector);
    const $freeCartonQuantity = $basketLine.find(BasketCheckout.freeCartonQuantitySelector);

    // determine value change
    let changeValueType = "quantity";
    const origBatchCode = $batchCode.data("orig") ?? "";
    const origRemark = $remark.data("orig") ?? "";
    const origFreeRollQuantity = $freeRollQuantity.data("orig") ?? "0";
    const origFreeCartonQuantity = $freeCartonQuantity.data("orig") ?? "0";

    if ($batchCode.length > 0 && origBatchCode !== ($batchCode.val() as string)) {
      changeValueType = "batchcode";
    } else if ($remark.length > 0 && origRemark !== ($remark.val() as string)) {
      changeValueType = "remark";
    } else if ($freeRollQuantity.length > 0 && origFreeRollQuantity + "" !== ($freeRollQuantity.val() as string)) {
      changeValueType = "freerolls";
    } else if (
      $freeCartonQuantity.length > 0 &&
      origFreeCartonQuantity + "" !== ($freeCartonQuantity.val() as string)
    ) {
      changeValueType = "freecartons";
    }

    let freeRollQuantity: any = $freeRollQuantity.val();
    if (typeof freeRollQuantity === "undefined" || freeRollQuantity === "") freeRollQuantity = "0";
    freeRollQuantity = freeRollQuantity.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");

    let freeCartonQuantity: any = $freeCartonQuantity.val();
    if (typeof freeCartonQuantity === "undefined" || freeCartonQuantity === "") freeCartonQuantity = "0";
    freeCartonQuantity = freeCartonQuantity.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");

    const data = {
      productId: productId,
      customerProductId: customerProductId,
      groupGuid: groupGuid,
      quantity: $quantity.val() as number,
      batchCode: $batchCode.val() as number,
      remark: $remark.val() as number,
      changeValueType: changeValueType,
      freeRollQuantity: freeRollQuantity as number,
      freeCartonQuantity: freeCartonQuantity as number,
    };

    axios
      .post<UpdateBasketLineResponse>("/api/basket/changeline", data)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          BasketCheckout.handleUpdateBasketLineSuccess(data);
        } else {
          if (data.message != null) {
            Toaster.pop({
              type: "warning",
              message: data.message,
            });
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {});
  }

  private static handleUpdateBasketLineSuccess(data: UpdateBasketLineResponse) {
    const quantitySelector = ".js-basket-line--quantity";
    const batchCodeSelector = ".js-basket-line--batch-code";
    const remarkSelector = ".js-basket-line--remark";
    const freeRollQuantitySelector = ".js-basket-line--free-roll-quantity";
    const freeCartonQuantitySelector = ".js-basket-line--free-carton-quantity";

    for (let line of data.lines) {
      const $elem = $(`div.js-basket-line[data-gg=${line.groupGuid}]`);

      if (!!line.message) {
        Toaster.pop({
          type: "success",
          message: line.message,
        });
      }

      const $quantity = $elem.find(quantitySelector);
      $quantity.val(line.quantity);

      const $batchCode = $elem.find(batchCodeSelector);
      $batchCode.val(line.batchNumber);
      $batchCode.data("orig", line.batchNumber);

      const $remark = $elem.find(remarkSelector);
      $remark.val(line.remark);
      $remark.data("orig", line.remark);

      const $freeRollQuantity = $elem.find(freeRollQuantitySelector);
      $freeRollQuantity.val(line.freeRollQuantity);
      $freeRollQuantity.data("orig", line.freeRollQuantity);

      const $freeCartonQuantity = $elem.find(freeCartonQuantitySelector);
      $freeCartonQuantity.val(line.freeCartonQuantity);
      $freeCartonQuantity.data("orig", line.freeCartonQuantity);

      $elem.find(".js-basket-line--info").attr("data-bs-content", line.priceTooltipText);
      $elem.find(".js-basket-line--total").html(line.lineTotalDisplay);

      let unitPriceDisplay = $elem.find(".js-basket-line--unit-price").html();

      const slashIndex = unitPriceDisplay.indexOf("/");
      if (slashIndex > 0) {
        unitPriceDisplay = line.unitPriceDisplay + unitPriceDisplay.substr(slashIndex - 1);
      } else {
        unitPriceDisplay = line.unitPriceDisplay;
      }

      $elem.find(".js-basket-line--unit-price").html(unitPriceDisplay);
      if (!!line.stockHtml) {
        $elem.find(".js-basket-line--stock").html(line.stockHtml);
      }
    }

    const basketTotals = `${data.miniBasket.currencySymbol} ${data.miniBasket.totalAmount.toFixed(2)}`;
    $(".js-basket-totals").find(".js-basket-totals--sub-total").html(basketTotals);

    if (!!data.message) {
      Toaster.pop({
        type:
          data.messageType == "success"
            ? "success"
            : data.messageType == "error"
            ? "error"
            : data.messageType == "warning"
            ? "warning"
            : "success",
        message: data.message,
      });
    }

    MiniBasketData.set(data.miniBasket);
    BasketTotalsData.set(data.basketTotals);
  }

  private static initDeleteBasket() {
    const $checkout = $(".nt-checkout");

    if ($checkout.length === 0) {
      return;
    }

    $checkout.on("click", ".js-delete-basket", (e) => {
      e.preventDefault();

      bootbox.confirm({
        message: Multilang.getTranslation(
          "checkout.message.confirm.delete-basket",
          "Do you want to delete this basket?"
        ),
        buttons: {
          confirm: {
            label: Multilang.getTranslation("checkout.message.confirm.delete-basket.yes", "Delete"),
            className: "btn-primary",
          },
          cancel: {
            label: Multilang.getTranslation("checkout.message.confirm.delete-basket.no", "Cancel"),
            className: "btn-secondary",
          },
        },
        callback: function (result: boolean) {
          if (!result) {
            return;
          }

          axios
            .delete<DeleteBasketResponse>("/api/basket/delete")
            .then((res: AxiosResponse<DeleteBasketResponse>) => res.data)
            .then((data: DeleteBasketResponse): void => {
              if (data.success) {
                for (let basketLine of data.basketLines) {
                  // DeepDive logging
                  const logData = {
                    productId: basketLine.productId,
                    customerProductId: basketLine.customerProductId,
                  };

                  Product.logDeep(Product.productEventType.ProductRemoveFromBasket, logData);

                  // GTM tracking
                  if (basketLine.dataLayerProduct !== null) {
                    const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
                    console.log("Calling natchGtm.trackRemoveFromCart", basketLine.dataLayerProduct);
                    natchGtm.trackRemoveFromCart(basketLine.dataLayerProduct, basketLine.dataLayerProduct._price);
                  }
                }

                // reload page
                window.location.reload();
              } else {
                if (data.message != null) {
                  Toaster.pop({
                    type: "warning",
                    message: data.message,
                  });
                }
              }
            })
            .catch((err) => console.error(err));
        },
      });
    });
  }

  private static initDeleteBasketLine() {
    $(".js-handle-delete-basket-line").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      let basketLine$ = $(e.currentTarget).closest(".js-basket-line");
      const data = {
        groupGuid: basketLine$.data("gg"),
      };

      // get GTM datalayer data in a safe way
      const jsonString = basketLine$.attr("data-datalayer-cart-item");
      const productDataLayerFields = jsonString ? JSON.parse(jsonString) : null;

      axios
        .post<DeleteBasketLineResponse>("/api/basket/deleteline", data)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            const totalAmountDisplay = `${data.miniBasket.currencySymbol} ${data.miniBasket.totalAmount}`;
            $(".js-basket-totals").find(".js-basket-totals--sub-total").html(totalAmountDisplay);

            const selector = "div.js-basket-line[data-gg=" + data.groupGuid + "]";

            // DeepDive Logging
            const logData = {
              productId: $(selector).data("pid"),
              customerProductId: $(selector).data("cpid"),
            };

            Product.logDeep(Product.productEventType.ProductRemoveFromBasket, logData);
            // End DeepDive Logging

            if (productDataLayerFields !== null) {
              const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
              console.log("Calling natchGtm.trackRemoveFromCart", productDataLayerFields);
              natchGtm.trackRemoveFromCart(productDataLayerFields, productDataLayerFields._price);
            }

            $(selector).remove();

            if (data.miniBasket.lineCount === 0) {
              window.location.reload();
            } else {
              if (data.message != null) {
                Toaster.pop({
                  type: "warning",
                  message: data.message,
                });
              }

              MiniBasketData.set(data.miniBasket);
            }
          } else {
            if (data.message != null) {
              Toaster.pop({
                type: "warning",
                message: data.message,
              });
            }
          }
        })
        .catch((err) => console.error(err));
    });
  }

  private static initFreeQuoteItems() {
    const toggleWrapperSelector = ".js-toggle-number--wrapper";
    const freeQuoteItemsCheckboxSelector = ".js-toggle-freequoteitems--checkbox";

    $(freeQuoteItemsCheckboxSelector).each(function () {
      const $currentField = $(this);
      const targetui = $currentField.data("target");
      const $input = $(targetui).find("input");
      if ($input != null && $input.length > 0 && ($input.val() || 0) > 0) {
        $currentField.prop("checked", true);
        $(targetui).parents(".collapse").addClass("show");
        $(targetui).find(toggleWrapperSelector).css("visibility", "visible");
      } else {
        $(targetui).find(toggleWrapperSelector).css("visibility", "hidden");
      }
    });

    $(freeQuoteItemsCheckboxSelector).on("change", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();
      const $currentField = $(e.currentTarget);
      const targetui = $currentField.data("target");
      const $input = $(targetui).find("input");
      let currentValue = $input.val() || 0;
      if ($currentField.is(":checked")) {
        if (currentValue <= 0) {
          currentValue = 1;
          $input.val(currentValue);
          $(targetui).find(toggleWrapperSelector).css("visibility", "visible");
          $input.trigger("change");
        }
      } else {
        $input.val(0);
        $(targetui).find(toggleWrapperSelector).css("visibility", "hidden");
        $input.trigger("change");
      }
    });
  }

  private static initPager() {
    const $checkoutBasket = $(".nt-checkout__basket");

    $checkoutBasket.on("click", ".js-change-page", (e) => {
      e.preventDefault();

      const newIx = $(e.currentTarget).data("ix");
      const pageIndexRegex = /(pageindex=)[^\&]+/;

      let redirectUrl = window.location.href;

      if (redirectUrl.match(pageIndexRegex)) {
        redirectUrl = redirectUrl.replace(pageIndexRegex, "$1" + newIx);
      } else {
        const separator = redirectUrl.indexOf("?") !== -1 ? "&" : "?";
        redirectUrl = `${redirectUrl}${separator}pageindex=${newIx}`;
      }

      window.location.href = redirectUrl;
    });
  }
}
