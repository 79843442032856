﻿export default class VideoObserver {
  static init() {
    let videoSpecialVideo = document.getElementById("video-special-player") as HTMLVideoElement;
    if (!videoSpecialVideo) {
      return;
    }

    const options: IntersectionObserverInit = {
      threshold: 0.1,
    };
    const videoObserver = new IntersectionObserver((entries, observed) => {
      entries.forEach(function (observed) {
        if (observed.isIntersecting) {
          videoSpecialVideo?.play();
          videoObserver.disconnect();
        }
      });
    }, options);
    videoObserver.observe(videoSpecialVideo);
  }
}
