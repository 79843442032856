import $ from "jquery";
import axios from "axios";
import NProgress from "nprogress";

// make clear to server that axios requests are AJAX requests
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const urlsExemptFromOverlay = ["/api/pricing/fetch"];

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // console.log("request interceptor:", config);
    if (NProgress) NProgress.start();
    if (urlsExemptFromOverlay.includes(config.url ?? "") === false) {
      $("body").css("cursor", "progress");
      $('<div class="nt-ajaxing-overlay"></div>').delay(300).prependTo("body").fadeIn(200);
    }
    return config;
  },
  function (error) {
    if (NProgress) NProgress.done();
    $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
    $(".nt-ajaxing-overlay").remove();
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    const config = response.config;
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (NProgress) NProgress.done();
    if (urlsExemptFromOverlay.includes(config.url ?? "") === false) {
      $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
      $(".nt-ajaxing-overlay").remove();
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (NProgress) NProgress.done();
    $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
    $(".nt-ajaxing-overlay").remove();
    return Promise.reject(error);
  }
);
