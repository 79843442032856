﻿import $ from "jquery";
import axios from "axios";
import { format } from "date-fns";
import * as Toaster from "./utils/toaster";
import Multilang from "./utils/multilang";
import Product from "./product";
import MiniBasketData from "../composables/mini-basket-data";

import { AddToBasketResponse } from "./models/basket";

// @ts-ignore
import datepicker from "js-datepicker";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";

export default class OrderList {
  private static datepickerStartDate: any;
  private static datepickerEndDate: any;

  static init() {
    OrderList.initDatePicker();
    OrderList.initMissingDetails();
    OrderList.initCollapsibleOrderData();
    OrderList.initReAddToBasket();
    OrderList.initPager();
  }

  private static initDatePicker() {
    const $datepickerStartDate = $(".js-order-search--start-date");
    const $datepickerStartDateIcon = $(".js-order-search--start-date-icon");

    const $datepickerEndDate = $(".js-order-search--end-date");
    const $datepickerEndDateIcon = $(".js-order-search--end-date-icon");

    if ($datepickerStartDate.length === 0 || $datepickerEndDate.length === 0) {
      return;
    }

    // https://www.npmjs.com/package/js-datepicker
    const options = {
      startDay: 1,
      noWeekends: true,
      disableYearOverlay: true,
      formatter: (input: any, date: any, instance: any) => {
        input.value = format(date, "dd/MM/yyyy");
      },
    };

    OrderList.datepickerStartDate = datepicker(".js-order-search--start-date", options);
    OrderList.datepickerEndDate = datepicker(".js-order-search--end-date", options);

    $datepickerStartDateIcon.on("click", (e: JQueryEventObject) => {
      e.stopPropagation();
      OrderList.datepickerStartDate.show();
    });

    $datepickerEndDateIcon.on("click", (e: JQueryEventObject) => {
      e.stopPropagation();
      OrderList.datepickerEndDate.show();
    });
  }

  static initMissingDetails() {
    $(".js-missing-order-details").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      Toaster.pop({
        type: "warning",
        message: Multilang.getTranslation(
          "order.product.maxbatchquantitynotavailable",
          "This product is no longer available."
        ),
      });
    });
  }

  private static initCollapsibleOrderData() {
    $(".js-order-details").on("show.bs.collapse", (e: JQueryEventObject) => {
      const $orderDetails = $(e.currentTarget);
      const $orderLine = $orderDetails.prev("tr");
      const $orderToggle = $orderLine.find(".ph-caret-down");

      OrderList.handleCollapsibleOrderData($orderToggle, true);
    });

    $(".js-order-details").on("hidden.bs.collapse", (e: JQueryEventObject) => {
      const $orderDetails = $(e.currentTarget);
      const $orderLine = $orderDetails.prev("tr");
      const $orderToggle = $orderLine.find(".ph-caret-up");

      OrderList.handleCollapsibleOrderData($orderToggle, false);
    });
  }

  private static handleCollapsibleOrderData($orderToggle: JQuery<Element>, show: boolean) {
    $orderToggle.toggleClass("ph-caret-down", !show).toggleClass("ph-caret-up", show);
  }

  private static initReAddToBasket() {
    $(".js-re-add-to-basket--button").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      const self = $(e.currentTarget);
      const productId = self.data("pid");
      const customerProductId = self.data("cpid");
      const batchCode = self.data("batch");
      const quantity = self.data("q");
      const unitSingular = self.data("unit");
      const unitPlural = self.data("units");

      // get GTM datalayer data in a safe way
      const jsonString = self.attr("data-datalayer-cart-item");
      const productDataLayerFields = (jsonString ? JSON.parse(jsonString) : null) as NatchOsDataLayerProduct;
      if (productDataLayerFields) {
        const listElement = self.closest("[data-datalayer-list-name]");
        const productDataLayerListName = listElement?.attr("data-datalayer-list-name") || "";
        const productDataLayerListId = listElement?.attr("data-datalayer-list-id") || "";
        productDataLayerFields.list_name = productDataLayerListName;
        productDataLayerFields.list_id = productDataLayerListId;
      }

      OrderList.reAddToBasket(
        productId,
        customerProductId,
        batchCode,
        quantity,
        unitSingular,
        unitPlural,
        productDataLayerFields
      );
    });
  }

  private static reAddToBasket(
    productId: number,
    customerProductId: number,
    batchCode: string,
    quantity: number,
    unitSingular: string,
    unitPlural: string,
    productDataLayerFields: NatchOsDataLayerProduct
  ) {
    const data = {
      productId: productId,
      customerProductId: customerProductId,
      quantity: quantity,
      batchCode: batchCode,
      attributionListItemId: "orders",
      attributionListItemName: "My Orders",
    };

    axios
      .post<AddToBasketResponse>("/api/basket/add", data)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          let message = Multilang.getTranslation("product.addtobasket.success");
          message = message.replace("{quantityadded}", data.addedQuantity.toString());
          if (data.addedQuantity !== 1) {
            message = message.replace("{unitplural}", unitPlural);
          } else {
            message = message.replace("{unitplural}", unitSingular);
          }

          Toaster.pop({
            type: "success",
            message: message,
          });

          const logData = {
            productId: productId,
            customerProductId: customerProductId,
            quantity: data.addedQuantity,
            batchCode: batchCode,
            fromOrder: true,
            mode: "Re-add",
          };

          Product.logDeep(Product.productEventType.ProductAddToBasket, logData);

          if (productDataLayerFields !== null) {
            const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
            productDataLayerFields.list_id = "orders";
            productDataLayerFields.list_name = "My Orders";
            productDataLayerFields._quantity = data.addedQuantity;
            productDataLayerFields._price = data.totalAmount;
            console.log("Calling natchGtm.trackAddToCart", productDataLayerFields, data.totalAmount);
            natchGtm.trackAddToCart(productDataLayerFields, data.totalAmount);
          }
        } else {
          if (data.message != null) {
            Toaster.pop({
              type: "error",
              message: data.message,
            });
          } else {
            Toaster.pop({
              type: "error",
              message: Multilang.getTranslation("product.addtobasket.failed"),
            });
          }
        }

        MiniBasketData.set(data.miniBasket);
      })
      .catch((err) => {
        console.error(err);
        Toaster.pop({
          type: "error",
          message: "Something went wrong",
        });
      });
  }

  private static initPager() {
    const $orderList = $(".nt-order-list");

    $orderList.on("click", ".js-change-page", (e) => {
      e.preventDefault();

      const $form = $orderList.find(".js-order-search--form");

      const newIx = $(e.currentTarget).data("ix");
      $form.find(".js-order-search--page-index").val(newIx);

      $form.submit();
    });
  }
}
