import { reactive, ref } from "vue";
import { MiniBasketResponse } from "../script/models/basket";

type MiniBasketLine = {
  imageUrl: string;
  productCode: string;
  quantity: number;
  unitPrice: number;
  lineTotal: number;
  quoteDiscountPercentage: number;
};

export default class MiniBasketData {
  static lineCount = ref(0);
  static totalAmount = ref(0);
  static totalDiscount = ref(0);
  static grandTotal = ref(0);
  static currencySymbol = ref("");
  static basketType = ref("");
  static productList = reactive({ items: [] as MiniBasketLine[] });

  static set(data: MiniBasketResponse) {
    this.lineCount.value = data.lineCount;
    this.totalAmount.value = data.totalAmount;
    this.currencySymbol.value = data.currencySymbol;
    this.basketType.value = data.basketType;
    this.grandTotal.value = data.grandTotal;
    this.totalDiscount.value = data.totalDiscount;

    // clear product list
    this.productList.items.splice(0, this.productList.items.length);

    // add product item
    for (let line of data.lines) {
      this.productList.items.push({
        imageUrl: line.imageUrl,
        productCode: line.productCode,
        quantity: line.quantity,
        unitPrice: line.unitPrice,
        quoteDiscountPercentage: line.quoteDiscountPercentage,
        lineTotal: line.lineTotal,
      });
    }
  }

  static addToLineCount(value: number) {
    this.lineCount.value = this.lineCount.value + value;
  }

  static addToProductList(
    imageUrl: string,
    productCode: string,
    quantity: number,
    unitPrice: number,
    quoteDiscountPercentage: number,
    lineTotal: number
  ) {
    this.productList.items.push({
      imageUrl: imageUrl,
      productCode: productCode,
      quantity: quantity,
      unitPrice: unitPrice,
      quoteDiscountPercentage: quoteDiscountPercentage,
      lineTotal: lineTotal,
    });
  }
}
