﻿import $ from "jquery";
import ClickEvent = JQuery.ClickEvent;

export class NavigateToLastFromHistory {
  private readonly $this: JQuery<HTMLElement>;

  constructor() {
    this.$this = $(".js-navigate-back");
    this.$this.on("click", (e) => NavigateToLastFromHistory.onClick(e));
  }

  private static onClick(e: ClickEvent): void {
    if (window.history.length > 1) {
      e.preventDefault();
      e.stopPropagation();
      window.history.back();
    }
    // else: perform default operation, meaning follow link href
  }
}

new NavigateToLastFromHistory();
