﻿import $$ from "../utils/double-dollar";
import { Loader } from "@googlemaps/js-api-loader";

function setFieldValue(form: HTMLFormElement | null, fieldName: string, value?: number) {
  if (form === null) return;
  if (value === undefined) return;
  const latField = form?.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
  if (!latField) return;
  latField.value = value.toString();
}

function loadGoogleMapsWithoutMap() {
  const launchElements = document.querySelectorAll(".js-start-google-autocomplete-without-map");

  // no point in loading twice and Loader will throw errors if called with different options:
  // "Loader must not be called again with different options"
  const firstLaunchElement = launchElements.length > 0 ? launchElements[0] : undefined;

  if (!firstLaunchElement) {
    return;
  }
  const input = firstLaunchElement as HTMLInputElement;
  if (!input) {
    return;
  }
  const apiKey = input.dataset.gak ?? "";
  const countries = (input.dataset.countries ?? "").split(",");
  const loader = new Loader({
    apiKey: apiKey,
    libraries: ["places"],
  });
  loader
    .load()
    .then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: countries },
        types: ["geocode"],
      });
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setFieldValue(input.form, "lat", place.geometry?.location?.lat());
        setFieldValue(input.form, "lng", place.geometry?.location?.lng());
        input.form?.submit();
      });
    })
    .catch((e) => {
      console.error(e);
    });
}

loadGoogleMapsWithoutMap();

$$(".js-submit-current-location", (element) => {
  const button = element as HTMLButtonElement;
  if (!button) {
    return;
  }

  button.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!navigator.geolocation) {
      console.error("Browser doesn't support Geolocation");
    }
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        setFieldValue(button.form, "lat", position.coords.latitude);
        setFieldValue(button.form, "lng", position.coords.longitude);
        button.form?.submit();
      },
      () => {
        console.error("The Geolocation service failed.");
      }
    );
  });
});
