﻿import $ from "jquery";
import axios from "axios";
import NatchGtm from "natch-gtm4";
import StoreFinderMap, { CustomerStoreDto, SocialMediaHandle } from "./components/store-finder-map";

declare var dataLayer: any[];
let natchGtm: NatchGtm | null;

export default class StoreFinderDetail {
  private static map: StoreFinderMap;
  private static storeDetailsUrl: string;

  static init(map: StoreFinderMap, storeDetailsUrl: string) {
    StoreFinderDetail.map = map;
    StoreFinderDetail.storeDetailsUrl = storeDetailsUrl;

    $(".nt-store-finder-card-list").on("click", ".js-store-finder--open-detail", (e) => {
      const $currentTarget = $(e.currentTarget);
      const storeId = $currentTarget.data("storeId");
      StoreFinderDetail.openStoreDetail(storeId);
    });

    $(".js-store-finder--close-detail").on("click", (e) => {
      $(".js-store-finder--store-detail").hide();
      $(".js-store-finder--close-detail").css("visibility", "hidden");

      map.resetToPreviousZoomAndLocation();
      map.unHighlightMarker();

      $(".js-store-finder--overview").show();
    });
  }

  static openStoreDetail(storeId: any) {
    $(".js-store-finder--overview").hide();
    axios.get(`${StoreFinderDetail.storeDetailsUrl}/${storeId}`).then((response) => {
      const store = response.data as CustomerStoreDto;

      const lat = store.geoLocation.latitude;
      const lng = store.geoLocation.longitude;

      const website = StoreFinderDetail.getClickableWebsite(store.website);

      StoreFinderDetail.map.highlightStoreMarker(storeId);
      StoreFinderDetail.map.zoomToLocation({ lat, lng });

      $(".js-set-store-name").text(store.storeName);
      $(".js-set-address-line1").text(store.address.streetAndNumber ?? "");
      $(".js-set-address-line2").text(store.address.locality ?? "");

      $(".js-set-phone").text(store.phoneNumber ?? "");
      $(".js-set-website").text(store.website ?? "");
      $(".js-set-website").attr("href", website);
      $(".js-set-website").attr("target", "_blank");
      $(".js-set-email").text(store.emailAddress ?? "");
      $(".js-set-email").attr("href", "mailto:" + store.emailAddress ?? "");

      let hiddenItems = 0;
      if (store.phoneNumber === "" || store.phoneNumber === undefined || store.phoneNumber === null) {
        $(".js-hide-phone").hide();
        hiddenItems++;
      } else {
        $(".js-hide-phone").show();
      }

      if (store.website === "" || store.website === undefined || store.website === null) {
        $(".js-hide-website").hide();
        hiddenItems++;
      } else {
        $(".js-hide-website").show();
      }

      if (store.emailAddress === "" || store.emailAddress === undefined || store.emailAddress === null) {
        $(".js-hide-email").hide();
        hiddenItems++;
      } else {
        $(".js-hide-email").show();
      }

      if (hiddenItems == 3) {
        $(".js-hide-contact-info").hide();
      } else {
        $(".js-hide-contact-info").show();
      }

      // $(".").text();
      // $(".").text();

      let hasSocialMedia = false;
      hasSocialMedia = StoreFinderDetail.setSocial("facebook", store.socialMediaHandles.facebook) || hasSocialMedia;
      hasSocialMedia = StoreFinderDetail.setSocial("instagram", store.socialMediaHandles.instagram) || hasSocialMedia;
      hasSocialMedia = StoreFinderDetail.setSocial("linkedin", store.socialMediaHandles.linkedIn) || hasSocialMedia;
      hasSocialMedia = StoreFinderDetail.setSocial("pinterest", store.socialMediaHandles.pinterest) || hasSocialMedia;
      hasSocialMedia = StoreFinderDetail.setSocial("youtube", store.socialMediaHandles.youTube) || hasSocialMedia;
      hasSocialMedia =
        StoreFinderDetail.setSocial(
          "vkontakte",
          store.address.countryCode == "RU" ? store.socialMediaHandles.vKontakte : undefined
        ) || hasSocialMedia;
      if (hasSocialMedia === false) {
        $(".nt-store-detail--social-media").hide();
      } else {
        $(".nt-store-detail--social-media").show();
      }

      StoreFinderDetail.setBrand("grandecoboutique", store.brandAvailability.grandecoBoutique);
      StoreFinderDetail.setBrand("grandecolife", store.brandAvailability.grandecoLife);
      StoreFinderDetail.setBrand("verticalart", store.brandAvailability.verticalArt);
      StoreFinderDetail.setBrand("wallfashion", store.brandAvailability.wallFashion);

      $(".js-store-finder--close-detail").css("visibility", "visible");
      $(".js-store-finder--store-detail").show();

      dataLayer.push({
        event: "store_open_details",
        storeName: store.storeName,
        storeAddress: `${store.address.streetAndNumber}, ${store.address.locality}, ${store.address.countryCode}`,
      });
    });
  }

  private static getClickableWebsite(website?: string): string {
    return (website =
      website?.startsWith("http://") || website?.startsWith("https://") ? website : `https://${website}`);
  }

  private static setSocial(name: string, handle?: SocialMediaHandle): boolean {
    const $social = $(`.js-set-${name}`);
    if (!handle?.url || !handle?.value) {
      $social.hide();
      return false;
    }

    $social.attr("href", handle?.url ?? "");
    $social.show();
    return true;
  }

  private static setBrand(name: string, active: boolean) {
    const $brand = $(`.js-set-${name}`);
    if (active) {
      $brand.show();
    } else {
      $brand.hide();
    }
  }
}
