import $ from "jquery";
import axios from "axios";

import * as axiosX from "./http/axios-extensions";

import Autocomplete from "./components/bootstrap-5-autocomplete";
import $$ from "./utils/double-dollar";

type SelectListItem = {
  text: string;
  value: string;
};

export default class Impersonation {
  static init() {
    Impersonation.initCustomersAutocomplete();
  }

  private static initCustomersAutocomplete() {
    $$(
      ".js-impersonation-select--customer",
      (el) =>
        new Autocomplete(el, {
          source: Impersonation.getCustomers,
          onSelectItem: (item, element) => {
            const $element = $(element);

            Impersonation.log(`customer selected: ${item.value}`);

            $(".js-impersonation-select--person").text("").val("");
            Impersonation.getPersons(item.value);

            const $form = $element.parents("form");
            $form.find(".js-form-field-customer-id").val(item.value);
          },
          highlightClass: "text-danger",
          treshold: 0,
          maximumItems: 10,
        })
    );
  }

  private static getCustomers() {
    const $input = $(".js-impersonation-select--customer");
    const searchTerm = $input.val();

    return axios
      .get(`/impersonation/customers?searchTerm=${searchTerm}`)
      .then((response) => response.data)
      .catch(axiosX.defaultCatch);
  }

  private static getPersons(customerId: string | number) {
    axios
      .get(`/impersonation/persons?customerId=${customerId}`)
      .then(Impersonation.fillPersons)
      .catch(axiosX.defaultCatch);
  }

  private static fillPersons(response: any) {
    let listItems: SelectListItem[] = response.data as SelectListItem[];
    var $select = $(".js-impersonation-select--person");

    $.each(listItems, function (index: number, item: SelectListItem) {
      $select.append(
        $("<option>", {
          value: item.value,
          text: item.text,
        })
      );
    });

    const $form = $(".form-impersonation");
    $form.find(".btn").prop("disabled", false);
  }

  private static log(msg: string) {
    console.log(msg);
  }
}
