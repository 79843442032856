﻿import StoreFinderMap from "./store-finder-map";

/**
 * This component will turn a <input> into a Google Places Autocomplete.
 * The Google Places API needs to be enabled in the Google Developer Console for the specified API key.
 */
export default class GoogleLocationAutoComplete {
  private readonly searchBoxId: string;
  private readonly searchBox: HTMLInputElement;
  private readonly mapComponent: StoreFinderMap;
  private countryCodes: string[];

  private autocomplete: google.maps.places.Autocomplete | undefined;

  /**
   * Construct a new GoogleLocationAutoComplete.
   * @param searchBoxId
   * @param mapComponent
   * @param countries a string containing a comma separated list of country codes
   */
  constructor(searchBoxId: string, mapComponent: StoreFinderMap, countries: string) {
    this.searchBoxId = searchBoxId;
    this.mapComponent = mapComponent;
    this.countryCodes = countries.split(",");

    this.searchBox = document.getElementById(this.searchBoxId) as HTMLInputElement;
    if (this.searchBox === null) {
      return;
    }

    this.bind();
  }

  public changeCountries(countryCodes: string[]) {
    this.countryCodes = countryCodes;
    if (!this.autocomplete) {
      return;
    }
    this.autocomplete.setComponentRestrictions({ country: this.countryCodes });
  }

  private bind() {
    const options = {
      componentRestrictions: { country: this.countryCodes },
      types: ["geocode"],
    };

    // API https://developers.google.com/maps/documentation/javascript/reference/3/places-widget#Autocomplete
    this.autocomplete = new google.maps.places.Autocomplete(this.searchBox, options);

    const searchParams = new URLSearchParams(window.location.search);

    function getSearchParam(prop: string) {
      return searchParams.get(prop?.toString() + "");
    }

    const query = {
      lat: getSearchParam("lat"),
      lng: getSearchParam("lng"),
    };
    if (!!query.lat && !!query.lng) {
      const lat: number = +(query.lat ?? "");
      const lng: number = +(query.lng ?? "");
      const coords = new google.maps.LatLng(lat, lng);
      this.mapComponent.updateSearch(this.searchBox.value, coords);
    } else {
      this.mapComponent.updateSearch(this.searchBox.value);
    }

    this.autocomplete.addListener("place_changed", () => {
      this.updateMapPlace();
    });

    this.searchBox.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        this.mapComponent.updateSearch(this.searchBox.value);
      }
    });
  }

  private hasKey = <T extends object>(obj: T, k: keyof any): k is keyof T => k in obj;

  private updateMapPlace() {
    if (this.autocomplete !== undefined) {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      console.log(place);
      if (place && place.geometry && place.geometry.location) {
        this.mapComponent.updateSearch(this.searchBox.value, place.geometry.location);
      }
    }
  }
}
