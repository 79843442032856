import $ from "jquery";

export default class ClientFilter {
  static init() {
    const $trigger = $("[data-nt-client-filter-container][data-nt-client-filter-item]");
    if ($trigger.length === 0) {
      return;
    }

    $trigger.on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      const self = $(e.currentTarget);
      const container = self.data("nt-client-filter-container");
      const item = self.data("nt-client-filter-item");
      const activeClass = self.data("nt-client-filter-active");
      const inactiveClass = self.data("nt-client-filter-inactive");

      var $container = $(container);

      // inactive filter buttons
      $("[data-nt-client-filter-container][data-nt-client-filter-item]").toggleClass(activeClass, false);
      $("[data-nt-client-filter-container][data-nt-client-filter-item]").toggleClass(inactiveClass, true);

      // active filter button
      self.toggleClass(inactiveClass, false);
      self.toggleClass(activeClass, true);

      if (item === "") {
        $container.find("[data-nt-client-filter-item]").toggleClass("d-none", false); // show all
      } else {
        $container.find("[data-nt-client-filter-item]").toggleClass("d-none", true); // hide all
        $container.find(`[data-nt-client-filter-item="${item}"]`).toggleClass("d-none", false); // show filtered items
      }
    });
  }
}
