import axios from "axios";
import $ from "jquery";
import debounce from "lodash-es/debounce";
// import MarkerClusterer from '@googlemaps/markerclustererplus';
import Marker = google.maps.Marker;
import StoreFinderDetail from "../store-finder-detail";

interface PlaceMark {
  storeId: string;
  name: string;
  latitude: number;
  longitude: number;
  countryCode: string;
  brands: string[];
}

interface CustomerStoreAddress {
  streetAndNumber?: string;
  locality?: string;
  countryCode: string;
}

interface GeoCoordinate {
  latitude: number;
  longitude: number;
}

export interface SocialMediaHandle {
  url: string;
  value: string;
}

interface SocialMediaHandles {
  facebook: SocialMediaHandle;
  instagram: SocialMediaHandle;
  linkedIn: SocialMediaHandle;
  pinterest: SocialMediaHandle;
  youTube: SocialMediaHandle;
  vKontakte: SocialMediaHandle;
}

interface BrandAvailablility {
  grandecoBoutique: boolean;
  wallFashion: boolean;
  grandecoLife: boolean;
  verticalArt: boolean;
}

export interface CustomerStoreDto {
  id: string;
  storeName: string;
  brandAvailability: BrandAvailablility;
  address: CustomerStoreAddress;
  geoLocation: GeoCoordinate;
  socialMediaHandles: SocialMediaHandles;
  phoneNumber?: string;
  emailAddress?: string;
  website?: string;
}

type FilterableMarker = {
  marker: Marker;
  store: PlaceMark;
};

export interface StoreFinderCountry {
  id: number;
  grandecoCountryCode: string;
  name: string;
  isoCode: string;
}

export default class StoreFinderMap {
  readonly DefaultMarkerIconUrl: string = "/img/store-finder/placemark.png";
  readonly HighlightedMarkerIconUrl: string = "/img/store-finder/placemark-highlight.png";

  map: google.maps.Map;
  center: google.maps.LatLngLiteral;
  markers: FilterableMarker[] = [];
  centerMarker?: Marker;
  highlightedMarker?: Marker;

  storeCardsElement: HTMLElement;
  markersUrl: string;
  nearestStoresUrl: string;
  storeDetailsUrl: string;

  /**
   * An array of country codes by which to filter the incoming map place marks.
   * Attention: use "UK" here, not "GB".
   */
  countryCodesFilter: string[];
  brands: string[] = ["GrandecoBoutique", "WallFashion", "GrandecoLife", "Vertical Art"];
  texts: any;

  infoWindow: google.maps.InfoWindow;

  readonly zoomedOutZoom = 5;
  readonly zoomedInZoom = 11;
  page = 1;

  previousCenter: google.maps.LatLngLiteral;
  previousZoom: number;

  constructor(
    mapElement: HTMLElement,
    mapId: string,
    storeCardsElement: HTMLElement,
    center: google.maps.LatLngLiteral,
    countries: StoreFinderCountry[],
    markersUrl: string,
    nearestStoresUrl: string,
    storeDetailsUrl: string,
    texts: any
  ) {
    this.map = new google.maps.Map(mapElement, {
      center,
      zoom: this.zoomedOutZoom,
      mapId: mapId,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
    });

    // this.centerMarker = new google.maps.Marker({
    //     position: center,
    //     title: "center",
    //     // enable to show the center of the map
    //     map: this.map
    // });

    this.storeCardsElement = storeCardsElement;
    this.markersUrl = markersUrl;
    this.nearestStoresUrl = nearestStoresUrl;
    this.storeDetailsUrl = storeDetailsUrl;
    this.center = center;
    this.countryCodesFilter = countries.map((c) => c.grandecoCountryCode);
    this.texts = texts;

    this.previousZoom = this.map.getZoom() ?? this.zoomedOutZoom;
    this.previousCenter = this.center;
    this.highlightedMarker = undefined;

    this.infoWindow = new google.maps.InfoWindow();

    this.map.addListener("center_changed", () => {
      this.centerChanged();
    });
    this.centerChanged();

    $(".js-center-to-current-location").on("click", () => {
      this.centerToCurrentLocation();
    });
  }

  initializeMap() {
    axios.get(`${this.markersUrl}`).then((response) => {
      const placeMarks = response.data as PlaceMark[];
      this.markers = placeMarks.map((placeMark, i) => {
        const myLatLng = new google.maps.LatLng(placeMark.latitude, placeMark.longitude);
        const marker = new google.maps.Marker({
          position: myLatLng,
          title: placeMark.name,
          icon: this.DefaultMarkerIconUrl,
        });
        if (this.countryCodesFilter.includes(placeMark.countryCode)) {
          marker.setMap(this.map);
        }
        marker.addListener("click", (e: any) => {
          this.markerClicked(marker, placeMark.storeId);
        });
        return {
          marker: marker,
          store: placeMark,
        };
      });

      // // @ts-ignore MarkerClustererOptions defined via script
      // const options: MarkerClustererOptions = {
      //     imagePath: "/img/store-finder/map-cluster-icons/m"
      // }
      // // @ts-ignore MarkerClusterer defined via script
      // new MarkerClusterer(this.map, markers, options);
    });
  }

  private centerChanged = debounce(() => {
    this.storeCardsElement.innerHTML = "";
    this.showNearestStores(1);
    this.page = 1;
  }, 500);

  public updateSearch(searchLocation: string, searchLatLng?: google.maps.LatLng) {
    console.log(`updating search with ${searchLocation} at ${searchLatLng}`);
    // this.storeIdToSelect = "";
    // if (this.isMapCenterChangedListenerAttached === false) {
    //     this.map.addListener("center_changed", () => {
    //         this.centerChanged();
    //     });
    // }
    this.checkGeocoding(searchLocation, searchLatLng);
  }

  public filterCountries(countryCodes: string[]) {
    this.countryCodesFilter = countryCodes;
    this.applyFilters();
  }

  public filterBrands(brands: string[]) {
    this.brands = brands;
    this.applyFilters();
  }

  public showNextNearestStores() {
    this.page++;
    return this.showNearestStores(this.page);
  }

  public zoomToLocation(pos: google.maps.LatLngLiteral) {
    console.log("Zooming In", pos);
    const center = this.map.getCenter();
    this.previousCenter = { lat: center?.lat() ?? 0, lng: center?.lng() ?? 0 };
    this.previousZoom = this.map.getZoom() ?? this.zoomedOutZoom;

    this.map.setCenter(pos);
    this.map.setZoom(this.zoomedInZoom);
    //this.markCenter(pos);
  }

  public unHighlightMarker() {
    if (this.highlightedMarker !== undefined) {
      this.highlightedMarker.setIcon(this.DefaultMarkerIconUrl);
      this.highlightedMarker.setZIndex(100);
      this.highlightedMarker = undefined;
    }
  }

  public highlightStoreMarker(storeId: string) {
    this.unHighlightMarker();
    const storeMarkers = this.markers.filter((m) => {
      return m.store.storeId === storeId;
    });
    if (storeMarkers.length > 0) {
      this.highlightedMarker = storeMarkers[0].marker;
      storeMarkers[0].marker.setZIndex(1000);
      storeMarkers[0].marker.setIcon(this.HighlightedMarkerIconUrl);
    }
  }

  public resetToPreviousZoomAndLocation() {
    this.map.setCenter(this.previousCenter);
    this.map.setZoom(this.previousZoom);
    this.markCenter(undefined);
  }

  private showNearestStores(page: number) {
    const url = this.generateStoresUrl(page);
    if (url.length === 0) {
      return;
    }
    this.getNextNearestStores(page);
    return axios.get(url).then((response) => {
      return this.appendStoresHtml(response.data as CustomerStoreDto[]);
    });
  }

  public getNextNearestStores(page: number) {
    const nextPage = page + 1;
    const url = this.generateStoresUrl(nextPage);
    if (url.length === 0) {
      return;
    }
    axios.get(url).then((response) => {
      return this.hideShowMoreStores(response.data as CustomerStoreDto[]);
    });
  }

  private hideShowMoreStores(stores: CustomerStoreDto[]) {
    if (!stores.length) {
      $(".js-store-finder-load-more").hide();
    } else {
      $(".js-store-finder-load-more").show();
    }
  }

  private generateStoresUrl(page: number): string {
    const take = 5;
    const skip = (page - 1) * take;
    const mapCenter = this.map.getCenter();
    if (!mapCenter) {
      return "";
    }
    const center = this.centerMarker?.getPosition() ?? mapCenter;
    const lat: number = center.lat();
    const lng: number = center.lng();
    const countryCodesJoined = this.countryCodesFilter.join(",");
    const brandsJoined = this.brands.join(",");
    const url = `${this.nearestStoresUrl}?latitude=${lat}&longitude=${lng}&countryCodes=${countryCodesJoined}&brands=${brandsJoined}&take=${take}&skip=${skip}`;
    return url;
  }

  private applyFilters() {
    this.markers.forEach((marker, i) => {
      // const allBrandsAvailableInStore = this.brands.every(brand => marker.store.brands.includes(brand));
      const hasAtLeastOneBrandAvailableInStore = this.brands.some((brand) => marker.store.brands.includes(brand));
      if (hasAtLeastOneBrandAvailableInStore && this.countryCodesFilter.includes(marker.store.countryCode)) {
        marker.marker.setMap(this.map);
      } else {
        marker.marker.setMap(null);
      }
    });
    this.centerChanged();
  }

  private appendStoresHtml(stores: CustomerStoreDto[]) {
    this.storeCardsElement.innerHTML += stores
      .map((store) => {
        return `
            <div class="nt-store-finder-card--wrapper">
                <div class="nt-store-finder-card">
                    <div class="nt-store-finder-card__header">
                        <div class="nt-store-finder-card__image">
                            <img src="/img/store-finder/placemark.png" alt="pin" class="img-fluid">
                        </div>
                        <div class="nt-store-finder-card__name">${store.storeName}</div>
                    </div>
                    <div class="nt-store-finder-card__body">
                        <div class="nt-store-finder-card__body__address">
                            ${store.address.streetAndNumber}, ${store.address.locality}
                        </div>
                        <div class="nt-store-finder-card__body__detail-link js-store-finder--open-detail" 
                            data-store-id="${store.id}" 
                            data-store-latitude="${store.geoLocation.latitude}" 
                            data-store-longitude="${store.geoLocation.longitude}">
                            ${this.texts["store-finder.list.more-info"]}
                            <i class="ph-caret-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        `;
      })
      .join("");
  }

  private checkGeocoding(searchLocation: string, searchLatLng?: google.maps.LatLng) {
    if (!!searchLocation && !searchLatLng) {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingRequests
      const geocoder = new google.maps.Geocoder();
      const request: google.maps.GeocoderRequest = {
        address: searchLocation,
        // componentRestrictions: { country: this.countryCodes }
      };
      geocoder.geocode(request, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && !!results) {
          searchLatLng = results[0].geometry.location;
          // this.$feedbackElement.hide();
          //this.markCenter({lat: searchLatLng.lat(), lng: searchLatLng.lng()});
          this.map.setCenter(searchLatLng);
          this.map.setZoom(this.zoomedInZoom);
          // history.replaceState(null, this.searchLocation, this.selectionUrl);
        } else {
          // this.$feedbackElement.show();
        }
      });
    } else if (searchLatLng !== undefined) {
      //this.markCenter({lat: searchLatLng.lat(), lng: searchLatLng.lng()});
      this.map.setCenter(searchLatLng);
      this.map.setZoom(this.zoomedInZoom);
    }
  }

  private centerToCurrentLocation() {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // mark position as 'center' on map before adjusting
          this.markCenter(pos);

          const adjusted = this.getAdjustedPosition(pos);
          this.map.setCenter(adjusted);
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter()!);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, this.infoWindow, this.map.getCenter()!);
    }
  }

  private markCenter(pos?: google.maps.LatLngLiteral) {
    if (!pos) {
      this.centerMarker?.setVisible(false);
    }
    if (!this.centerMarker) {
      this.centerMarker = new google.maps.Marker({
        position: pos,
        title: "center",
        // enable to show the center of the map
        map: this.map,
        visible: true,
      });
    } else {
      this.centerMarker.setPosition(pos);
      this.centerMarker.setVisible(true);
    }
  }

  private getAdjustedPosition(pos: google.maps.LatLngLiteral) {
    const bounds = this.map.getBounds();
    const result = {
      lat: pos.lat,
      lng: pos.lng,
    };
    if (!!bounds) {
      // adjusting center because of search box which offsets the view
      const lngDiff = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();
      result.lng = result.lng - lngDiff / 8;
    }
    return result;
  }

  private handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng
  ) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(this.map);
  }

  private markerClicked(marker: google.maps.Marker, storeId: string) {
    StoreFinderDetail.openStoreDetail(storeId);
  }
}
