import StoreFinderMap, { StoreFinderCountry } from "./components/store-finder-map";
import GoogleLocationAutoComplete from "./components/location-autocomplete";
import $ from "jquery";
import "./utils/array-extensions";
import StoreFinderDetail from "./store-finder-detail";

let map: StoreFinderMap;
let locationAutocomplete: GoogleLocationAutoComplete;
let storeDetailsUrl: string;

/**
 * Initialized the map. This function is passed as parameter to the script that
 * initializes the Google Maps API: e.g. https://maps.googleapis.com/maps/api/js?key=API_KEY&callback=initMap
 */
export default function initMap(): void {
  const mapElement = document.getElementById("map") as HTMLElement;
  const storeCardsElement = document.querySelector(".nt-store-finder-card-list") as HTMLElement;
  const centerString = mapElement?.dataset.center ?? "";
  const centerCoords = centerString.split(",").map(Number);
  const center = { lat: centerCoords.first() ?? 0, lng: centerCoords.last() ?? 0 };
  const markersUrl = mapElement?.dataset.markersUrl ?? "";
  const nearestStoresUrl = mapElement?.dataset.nearestStoresUrl ?? "";
  storeDetailsUrl = mapElement?.dataset.storeDetailsUrl ?? "";
  const mapId = mapElement?.dataset.mapId ?? "";
  const countriesJson = mapElement?.dataset.countries ?? "";
  const textsElementId = mapElement?.dataset.textsElementId ?? "";

  const countries = JSON.parse(countriesJson) as StoreFinderCountry[];
  const texts: any = {};
  [].slice.call(document.getElementById(textsElementId)?.children).map((child: any) => {
    if (!child) return;
    texts[child.dataset.textKey] = child.innerText;
  });
  map = new StoreFinderMap(
    mapElement,
    mapId,
    storeCardsElement,
    center,
    countries,
    markersUrl,
    nearestStoresUrl,
    storeDetailsUrl,
    texts
  );
  map.initializeMap();

  const countriesCodes = countries.map((c) => c.isoCode).join(",");
  locationAutocomplete = new GoogleLocationAutoComplete("search-box-location", map, countriesCodes);

  StoreFinderDetail.init(map, storeDetailsUrl);
}

declare global {
  interface Window {
    initMap: () => void;
  }
}
window.initMap = initMap;

$(".js-store-finder-select-brand").on("click", function (e) {
  // stop dropdown from closing
  e.stopPropagation();
});

$(".js-store-finder-select-country").on("click", function (e) {
  // stop dropdown from closing
  e.stopPropagation();
});

$(".js-store-finder-search-country").on("click", function (e) {
  const selectedCountries: string[] = [];
  $('.js-store-finder-select-country input[name="country-selection"]:checked').each(function (index, element) {
    selectedCountries.push($(element).val() as string);
  });
  map.filterCountries(selectedCountries);
  locationAutocomplete.changeCountries(selectedCountries);
});

$(".js-store-finder-search-brands").on("click", function (e) {
  const selectedValues: string[] = [];
  $('.js-store-finder-select-brand input[name="brand-selection"]:checked').each(function (index, element) {
    selectedValues.push($(element).val() as string);
  });
  map.filterBrands(selectedValues);
});

$(".js-store-finder-load-more").on("click", function (e) {
  const $currentTarget = $(e.currentTarget);
  const page: number = +$currentTarget.data("page") + 1;
  map.showNextNearestStores()?.then(() => {
    $currentTarget.data("page", page);
  });
});

$(".js-store-finder--toggle-results").on("click", (e) => {
  const $currentTarget = $(e.currentTarget);
  const $results = $(".js-store-finder--results");
  $results.slideToggle({
    duration: 800,
    complete: () => {
      const isVisible = $results.is(":visible");
      const isHidden = !isVisible;
      $currentTarget.toggleClass("active", isHidden);
    },
  });
});
