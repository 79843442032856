import $ from "jquery";
import axios from "axios";
import head from "lodash-es/head";

import $$ from "./utils/double-dollar";
import * as axiosExt from "./http/axios-extensions";
import Autocomplete, { AutocompleteOptions, AutocompleteItem } from "./components/bootstrap-5-autocomplete";

interface SearchResult {
  id: number;
  description: string;
  url: string;
}

class SearchAutocomplete {
  autocomplete: Autocomplete;
  cachedResults: SearchResult[];
  $searchTerm: JQuery<Element>;

  constructor(element: Element) {
    this.cachedResults = [];
    this.$searchTerm = $(element);

    let options: AutocompleteOptions = {
      source: () => {
        return this.getSuggestions();
      },
      onSelectItem: (item: AutocompleteItem, element: any) => {
        const cachedResult = head(
          this.cachedResults.filter((result: SearchResult) => result.id === parseInt(item.value))
        );

        document.location.href = cachedResult?.url as string;
      },
      highlightClass: "text-success",
      treshold: 0,
      maximumItems: 10,
    };

    this.autocomplete = new Autocomplete(element, options);
  }

  private async getSuggestions() {
    const input = this.$searchTerm.val() as string;

    if (input === "" || input.length < 3) {
      return Promise.resolve();
    }

    try {
      const response = await axios.get(`/api/product/suggest?q=${input}`);
      this.cachedResults = response.data as SearchResult[];

      let dict: any = {};
      this.cachedResults.forEach((el) => (dict[el.description] = el.id));

      return dict;
    } catch (error) {
      return axiosExt.defaultCatch(error);
    }
  }
}

$$(".js-search-autocomplete", (el) => new SearchAutocomplete(el));
