/* https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp */

export default class ImageMagnifier {
  static image: HTMLImageElement | null;
  static container: HTMLElement | null;
  static glass: HTMLElement | null;
  static zoom: number;
  static width: number;
  static height: number;
  static bgWidth: number;

  static init(target: string, zoomLevel: number) {
    ImageMagnifier.zoom = zoomLevel;

    ImageMagnifier.image = document.getElementById(target) as HTMLImageElement;

    if (ImageMagnifier.image === null) {
      return;
    }

    // small timeout to create a more robust magnifier glass, in which the background size is known on time
    setTimeout(() => {
      ImageMagnifier.createMagnifier();
    }, 500);
  }

  static createMagnifier() {
    const isMobile = "ontouchstart" in document.documentElement && navigator.userAgent.match(/Mobi/);
    if (isMobile) {
      return;
    }

    if (ImageMagnifier.image === null) {
      return;
    }

    /* check if magnifier glass already exists */
    if (typeof ImageMagnifier.glass !== "undefined") {
      return;
    }

    /* create magnifier glass */
    ImageMagnifier.glass = document.createElement("DIV");
    ImageMagnifier.glass.setAttribute("class", "c-image-magnifier-glass");

    /* insert magnifier glass into container */
    ImageMagnifier.container = ImageMagnifier.image.parentElement;

    if (ImageMagnifier.container !== null) {
      ImageMagnifier.container.insertBefore(ImageMagnifier.glass, ImageMagnifier.image);
    }

    /* set properties for the magnifier glass */
    ImageMagnifier.glass.style.opacity = "0";
    ImageMagnifier.glass.style.backgroundImage =
      "url('" + ImageMagnifier.image.getAttribute("data-magnifier-image") + "')";
    ImageMagnifier.glass.style.backgroundRepeat = "no-repeat";
    ImageMagnifier.glass.style.backgroundSize =
      ImageMagnifier.image.width * ImageMagnifier.zoom +
      "px " +
      ImageMagnifier.image.height * ImageMagnifier.zoom +
      "px";
    ImageMagnifier.bgWidth = 3;
    ImageMagnifier.width = ImageMagnifier.glass.offsetWidth / 2;
    ImageMagnifier.height = ImageMagnifier.glass.offsetHeight / 2;

    /* execute a function when someone moves the magnifier glass over the image */
    ImageMagnifier.glass.addEventListener("mousemove", ImageMagnifier.moveMagnifier);
    ImageMagnifier.container?.addEventListener("mousemove", ImageMagnifier.moveMagnifier);
    ImageMagnifier.container?.addEventListener("mouseover", ImageMagnifier.showMagnifier);
    ImageMagnifier.container?.addEventListener("mouseleave", ImageMagnifier.hideMagnifier);

    /* and also for touch screens */
    ImageMagnifier.glass.addEventListener("touchmove", ImageMagnifier.moveMagnifier);
    ImageMagnifier.container?.addEventListener("touchmove", ImageMagnifier.moveMagnifier);
    ImageMagnifier.container?.addEventListener("touchstart", ImageMagnifier.showMagnifier);
    ImageMagnifier.container?.addEventListener("touchend", ImageMagnifier.hideMagnifier);
  }

  static showMagnifier(e: Event) {
    if (ImageMagnifier.glass === null) {
      return;
    }

    ImageMagnifier.glass.style.opacity = "1";
  }

  static hideMagnifier(e: Event) {
    if (ImageMagnifier.glass === null) {
      return;
    }

    ImageMagnifier.glass.style.opacity = "0";
  }

  static moveMagnifier(e: Event) {
    let pos, x, y;

    /* prevent any other actions that may occur when moving over the image */
    e.preventDefault();

    if (ImageMagnifier.image === null || ImageMagnifier.glass === null) {
      return;
    }

    /* get the cursor's x and y positions */
    pos = ImageMagnifier.getCursorPosition(e);

    if (typeof pos === "undefined") {
      return;
    }

    x = pos.x;
    y = pos.y;

    /* prevent the magnifier glass from being positioned outside the image */
    if (x > ImageMagnifier.image.width - ImageMagnifier.width / ImageMagnifier.zoom) {
      x = ImageMagnifier.image.width - ImageMagnifier.width / ImageMagnifier.zoom;
    }
    if (x < ImageMagnifier.width / ImageMagnifier.zoom) {
      x = ImageMagnifier.width / ImageMagnifier.zoom;
    }
    if (y > ImageMagnifier.image.height - ImageMagnifier.height / ImageMagnifier.zoom) {
      y = ImageMagnifier.image.height - ImageMagnifier.height / ImageMagnifier.zoom;
    }
    if (y < ImageMagnifier.height / ImageMagnifier.zoom) {
      y = ImageMagnifier.height / ImageMagnifier.zoom;
    }

    /* set the position of the magnifier glass */
    ImageMagnifier.glass.style.left = x - ImageMagnifier.width + "px";
    ImageMagnifier.glass.style.top = y - ImageMagnifier.height + "px";

    /* display what the magnifier glass "sees" */
    ImageMagnifier.glass.style.backgroundPosition =
      "-" +
      (x * ImageMagnifier.zoom - ImageMagnifier.width + ImageMagnifier.bgWidth) +
      "px -" +
      (y * ImageMagnifier.zoom - ImageMagnifier.height + ImageMagnifier.bgWidth) +
      "px";
  }

  static getCursorPosition(e: any) {
    let a,
      x = 0,
      y = 0;

    e = e || window.event;

    if (ImageMagnifier.image === null) {
      return;
    }

    /* get the x and y positions of the image */
    a = ImageMagnifier.image.getBoundingClientRect();

    /* calculate the cursor's x and y coordinates, relative to the image */
    x = e.pageX - a.left;
    y = e.pageY - a.top;

    /* consider any page scrolling */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;

    return { x: x, y: y };
  }

  static reloadImage(imageSrc: string, magnifierToggle: string) {
    if (ImageMagnifier.image === null) {
      return;
    }

    // set image element
    ImageMagnifier.image.src = imageSrc;

    if (typeof ImageMagnifier.glass === "undefined" || ImageMagnifier.glass === null) {
      return;
    }

    // toggle glass element
    if (magnifierToggle === "on") {
      ImageMagnifier.glass.classList.add("c-image-magnifier-glass");
    } else {
      ImageMagnifier.glass.classList.remove("c-image-magnifier-glass");
    }
  }
}
