import $ from "jquery";

export default class Faq {
  static init() {
    Faq.initCollapsibleAnswer();
  }

  private static initCollapsibleAnswer() {
    $(".js-faq-answer").on("show.bs.collapse", (e: JQueryEventObject) => {
      const $faqAnswer = $(e.currentTarget);
      const $faqItem = $faqAnswer.closest(".js-faq-item");
      const $faqToggle = $faqItem.find(".ph-plus");

      Faq.handleCollapsibleAnswer($faqItem, $faqToggle, true);
    });

    $(".js-faq-answer").on("hidden.bs.collapse", (e: JQueryEventObject) => {
      const $faqAnswer = $(e.currentTarget);
      const $faqItem = $faqAnswer.closest(".js-faq-item");
      const $faqToggle = $faqItem.find(".ph-minus");

      Faq.handleCollapsibleAnswer($faqItem, $faqToggle, false);
    });
  }

  private static handleCollapsibleAnswer($faqItem: JQuery<Element>, $faqToggle: JQuery<Element>, show: boolean) {
    $faqItem.toggleClass("nt-page--dark", show);
    $faqToggle.toggleClass("ph-plus", !show).toggleClass("ph-minus", show);

    const $faqToggleBtn = $faqToggle.closest(".nt-btn-circle");
    $faqToggleBtn.toggleClass("nt-btn-circle--dark", !show).toggleClass("nt-btn-circle--light", show);
  }
}
