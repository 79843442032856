﻿import $ from "jquery";
import axios from "axios";
import * as Toaster from "./utils/toaster";
import Multilang from "./utils/multilang";
import Forms from "./utils/forms";
import MiniBasketData from "../composables/mini-basket-data";
import ImageMagnifier from "./components/image-magnifier";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";
import { AddToBasketResponse } from "./models/basket";

export default class Product {
  private static inHandlingOverpacks = false;

  private static natchGtm: NatchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);

  static productEventType = {
    NotSet: 0,
    ProductDetailView: 1,
    ProductAddToBasket: 2,
    ProductRemoveFromBasket: 3,
    ProductPurchase: 4,
    ProductDocumentDownload: 5,
    ProductPrint: 6,
    ProductShare: 7,
  };

  static init() {
    Product.initDetailImage();
    Product.initFavorites();
    Product.initAddToBasket();
    Product.initCollapsibleSectionData();
    Product.initClientClicks();
  }

  static logDeep(type: number, data: object) {
    let queryString = `?data=${encodeURIComponent(JSON.stringify(data))}`;
    queryString += `&productEventTypeId=${type}`;

    const call = $.ajax({
      type: "PUT",
      url: `/api/product/logging${queryString}`,
      cache: false,
      processData: true,
    });

    call.fail((jqXhr: any, textStatus: any, errorThrown: any) => {
      console.warn(`DeepLogging: ${textStatus} | ${errorThrown}`);
    });
  }

  private static initDetailImage() {
    const $detailImage = $("#product-detail-image");

    // check in JS as the error handler of the image might result in a "no-image"
    if ($detailImage.length > 0 && $detailImage.prop("src").includes("/img/no-image.png")) {
      return;
    }

    // set magnifier
    ImageMagnifier.init("product-detail-image", 2);

    // set gallery
    $(".js-product-detail--gallery").toggleClass("d-none", false).toggleClass("d-flex", true);

    $(".js-product-detail--gallery-toggle").on("click", (e: JQueryEventObject) => {
      $(".js-product-detail--gallery-toggle").toggleClass("active", false);

      const $toggle = $(e.currentTarget);
      $toggle.toggleClass("active", true);

      ImageMagnifier.reloadImage($toggle.data("image"), $toggle.data("magnifier-toggle"));
    });
  }

  private static initFavorites() {
    $(".js-favorite--toggle").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      const $toggle = $(e.currentTarget);
      const flag = $toggle.attr("data-flag") === "1";
      const productId = $toggle.data("pid");

      // datalayer only available in lists with all data
      const $elem = $toggle.closest("[data-datalayer-list-item]");
      const jsonString = $elem?.attr("data-datalayer-list-item");
      const productDataLayerFields = (jsonString ? JSON.parse(jsonString) : null) as NatchOsDataLayerProduct;
      if (productDataLayerFields) {
        const $listElement = $toggle.closest("[data-datalayer-list-name]");
        const productDataLayerListName = $listElement?.attr("data-datalayer-list-name") || "";
        const productDataLayerListId = $listElement?.attr("data-datalayer-list-id") || "";
        productDataLayerFields.list_name = productDataLayerListName;
        productDataLayerFields.list_id = productDataLayerListId;
      }

      Product.handleProductFavorite(productId, !flag, $toggle, productDataLayerFields);
    });
  }

  private static handleProductFavorite(
    productId: number,
    flag: boolean,
    $toggle: any,
    dataLayerProduct: NatchOsDataLayerProduct
  ) {
    if (flag) {
      axios
        .put(`/api/product/favorite?productId=${productId}`)
        .then((res) => {
          Product.toggleProductFavorite($toggle, flag);
          if (dataLayerProduct) {
            console.log("Calling natchGtm.trackAddToFavorites", dataLayerProduct);
            Product.natchGtm.trackAddToFavorites(dataLayerProduct, dataLayerProduct._price);
          }
        })
        .catch((err) =>
          Toaster.pop({
            type: "error",
            message: "Something went wrong",
          })
        );
    } else {
      axios
        .delete(`/api/product/favorite?productId=${productId}`)
        .then((res) => Product.toggleProductFavorite($toggle, flag))
        .catch((err) => console.error(err));
    }
  }

  private static toggleProductFavorite($toggle: any, flag: boolean) {
    $toggle.attr("data-flag", flag ? "1" : "0");
    $toggle.find("i").toggleClass("ph-star-fill", flag).toggleClass("ph-star", !flag);

    const $toggleText = $toggle.find(".js-favorite--toggle-text");
    $toggleText.html(
      flag
        ? Multilang.getTranslation("product.detail.button.removefav")
        : Multilang.getTranslation("product.detail.button.addtofav")
    );

    if (flag) {
      Toaster.pop({
        type: "success",
        message: Multilang.getTranslation("favorite.added"),
      });
    } else {
      Toaster.pop({
        type: "success",
        message: Multilang.getTranslation("favorite.removed"),
      });
    }
  }

  private static initAddToBasket() {
    $(".js-add-to-basket--form").on("submit", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $button = $(e.currentTarget).find(".js-add-to-basket--button");
      const productId = $button.data("pid");
      const customerProductId = $button.data("cpid");
      const unit = $button.data("unit");
      const unitPlural = $button.data("units");
      const quantitySelector = $button.data("qsel");

      // get GTM datalayer data in a safe way
      const jsonString = $button.attr("data-datalayer-cart-item");
      const productDataLayerFields = jsonString ? JSON.parse(jsonString) : null;
      if (productDataLayerFields) {
        const listElement = $button.closest("[data-datalayer-list-name]");
        const productDataLayerListName = listElement?.attr("data-datalayer-list-name") || "";
        const productDataLayerListId = listElement?.attr("data-datalayer-list-id") || "";
        productDataLayerFields.list_name = productDataLayerListName;
        productDataLayerFields.list_id = productDataLayerListId;
      }

      let quantityString = "1";
      if (quantitySelector != null) {
        quantityString = $(quantitySelector).val() as string;
      }

      const quantity = parseInt(quantityString);
      // if (quantity + "" !== quantityString || quantity <= 0) {
      //   Product.flickerClass($(quantitySelector), "nt-haserror");
      //   return;
      // }

      let mustBail = false;
      if (!Product.inHandlingOverpacks) {
        Product.inHandlingOverpacks = true;

        mustBail = Product.handleOverpacks($button, quantity, unit, unitPlural, $(quantitySelector));
        if (mustBail === false) {
          Product.inHandlingOverpacks = false;
        }
      }

      if (mustBail) {
        return;
      }

      const data = {
        productId: productId,
        customerProductId: customerProductId,
        quantity: quantity,
        attributionListItemId: productDataLayerFields?.list_id,
        attributionListItemName: productDataLayerFields?.list_name,
      };

      axios
        .post<AddToBasketResponse>("/api/basket/add", data)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            let message = Multilang.getTranslation("product.addtobasket.success");
            message = message.replace("{quantityadded}", data.addedQuantity.toString());
            if (data.addedQuantity !== 1) {
              message = message.replace("{unitplural}", unitPlural);
            } else {
              message = message.replace("{unitplural}", unit);
            }

            Toaster.pop({
              type: "success",
              message: message,
            });

            if (productDataLayerFields !== null) {
              // list already set, only setting quantity and price here
              productDataLayerFields._quantity = data.addedQuantity;
              productDataLayerFields._price = data.totalAmount;
              console.log("Calling natchGtm.trackAddToCart", productDataLayerFields, data.totalAmount);
              Product.natchGtm.trackAddToCart(productDataLayerFields, data.totalAmount);
            }

            const deepDiveLogData = {
              productId: productId,
              customerProductId: customerProductId,
              quantity: data.addedQuantity,
              mode: "Normal",
            };

            Product.logDeep(Product.productEventType.ProductAddToBasket, deepDiveLogData);
          } else {
            if (data.message != null) {
              Toaster.pop({
                type: "error",
                message: data.message,
              });
            } else {
              Toaster.pop({
                type: "error",
                message: Multilang.getTranslation("product.addtobasket.failed"),
              });
            }
          }

          MiniBasketData.set(data.miniBasket);
        })
        .catch((err) => {
          console.error(err);
          Toaster.pop({
            type: "error",
            message: "Something went wrong",
          });
        })
        .finally(() => {
          Forms.resetSubmitButtons();
        });
    });
  }

  private static handleOverpacks($sel: any, quantity: any, unit: any, unitPlural: any, $input: any) {
    let $parent = $sel.parents(".nt-product-detail");

    if ($parent.length === 0) {
      $parent = $sel.parents(".nt-product-card");
    }

    if ($parent.count === 0) {
      return false;
    }

    const overpacks = $parent.data("ovps");
    const suggestNextOverpack = $parent.data("sno") === 1;

    if (suggestNextOverpack) {
      let overpack1 = null;
      for (let ix = 0; ix < overpacks.length; ix++) {
        const overpack = overpacks[ix];
        if (overpack.quantity === 1) {
          overpack1 = overpack;
        }
      }

      for (let ix = overpacks.length - 1; ix >= 0; ix--) {
        const overpack = overpacks[ix];

        const rest = quantity % overpack.quantity;
        const overpackCount = Math.floor(quantity / overpack.quantity);
        const alertQuantity = (overpack.quantity * 80) / 100;

        if (
          overpack1 != null &&
          rest > alertQuantity /*&& quantity < overpack.quantity */ &&
          overpack.unitPrice < overpack1.unitPrice
        ) {
          const newQuantity = (overpackCount + 1) * overpack.quantity;

          let body = Multilang.getTranslation("product.addtobasket.betterprice.body");
          body = body.replace("{newprice}", parseInt(overpack.unitPrice).toFixed(2)); // TODO: see globalize TS package https://www.npmjs.com/package/@types/globalize
          body = body.replace("{oldprice}", parseInt(overpack1.unitPrice).toFixed(2)); // TODO: see globalize TS package https://www.npmjs.com/package/@types/globalize
          body = body.replace("{newquantity}", newQuantity.toFixed(0)); // TODO: see globalize TS package https://www.npmjs.com/package/@types/globalize

          if (newQuantity === 1) {
            body = body.replace("{unitplural}", unit);
          } else {
            body = body.replace("{unitplural}", unitPlural);
          }

          Product.overpackAlert(
            Multilang.getTranslation("product.addtobasket.betterprice.title"),
            body,
            newQuantity,
            (newQ: any, flag: any, cancel: any) => {
              if (!cancel) {
                if (flag) {
                  $input.val(newQ);
                }

                $sel.trigger("click");
              }

              Product.inHandlingOverpacks = false;
            }
          );

          return true;
        }
      }
    }

    return false;
  }

  private static overpackAlert(heading: any, formContent: any, newQuantity: any, callback: any) {
    let html =
      '<div id="dynamicModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirm-modal" aria-hidden="true">';
    html += '<div class="modal-dialog">';
    html += '<div class="modal-content">';
    html += '<div class="modal-header">';
    html += '<a class="close" data-dismiss="modal">×</a>';
    html += `<h4>${heading}</h4>`;
    html += "</div>";
    html += '<div class="modal-body">';
    html += formContent;
    html += "</div>";
    html += '<div class="modal-footer">';
    html += `<span class="btn btn-primary" id="modalYesButton" data-dismiss="modal">${Multilang.getTranslation(
      "product.overpackalert.yesplease"
    )}</span>`;
    html += `<span class="btn btn-primary" id="modalNoButton" data-dismiss="modal">${Multilang.getTranslation(
      "product.overpackalert.nothanks"
    )}</span>`;
    html += `<span class="btn btn-primary" id="modalCancelButton" data-dismiss="modal">${Multilang.getTranslation(
      "common.cancel"
    )}</span>`;
    html += "</div>"; // content
    html += "</div>"; // dialog
    html += "</div>"; // footer
    html += "</div>"; // modalWindow
    $("body").append(html);

    $("#dynamicModal").modal();
    $("#dynamicModal").modal("show");

    $("#modalYesButton").on("click", (e: JQueryEventObject) => {
      callback(newQuantity, true, false);
    });

    $("#modalNoButton").on("click", (e: JQueryEventObject) => {
      callback(newQuantity, false, false);
    });

    $("#modalCancelButton").on("click", (e: JQueryEventObject) => {
      callback(newQuantity, false, true);
    });

    $("#dynamicModal").on("hidden.bs.modal", (e: JQueryEventObject) => {
      $("#modalYesButton").unbind("click");
      $("#modalNoButton").unbind("click");
      $("#modalCancelButton").unbind("click");
      callback(newQuantity, false, true);
      $(e.currentTarget).remove();
    });
  }

  private static initCollapsibleSectionData() {
    $(".js-product-detail-section-data").on("show.bs.collapse", (e: JQueryEventObject) => {
      const $section = $(e.currentTarget);
      const $sectionData = $section.closest(".js-product-detail-section");
      const $sectionToggle = $sectionData.find(".ph-caret-down");

      Product.handleCollapsibleSectionData($sectionToggle, true);
    });

    $(".js-product-detail-section-data").on("hidden.bs.collapse", (e: JQueryEventObject) => {
      const $section = $(e.currentTarget);
      const $sectionData = $section.closest(".js-product-detail-section");
      const $sectionToggle = $sectionData.find(".ph-caret-up");

      Product.handleCollapsibleSectionData($sectionToggle, false);
    });
  }

  private static handleCollapsibleSectionData($sectionToggle: JQuery<Element>, show: boolean) {
    $sectionToggle.toggleClass("ph-caret-down", !show).toggleClass("ph-caret-up", show);
  }

  private static initClientClicks() {
    $(".js-deep-log").on("click", (e: JQueryEventObject) => {
      const self = $(e.currentTarget);

      const logData = {
        productId: self.data("pid"),
        customerProductId: self.data("cpid"),
        href: self.attr("href"),
      };

      Product.logDeep(Product.productEventType.ProductDocumentDownload, logData);
    });
  }
}
