import * as Toaster from "../utils/toaster";
import * as HttpProblemDetails from "./http-problem-details";

export function defaultCatch(error: any) {
  // console.error("axios-extensions.ts: defaultCatch(error) with error =", error);
  if (error.response) {
    const details = HttpProblemDetails.asHttpProblemDetails(error.response.data, JSON.stringify(error.response));
    if (details.status < 500) {
      Toaster.pop({
        type: "warning",
        message: details.detail,
        title: details.title,
      });
    } else {
      Toaster.pop({
        type: "error",
        message: details.detail,
        title: details.title,
      });
    }
  } else if (error.request) {
    Toaster.pop({
      type: "error",
      message: `No response received for request ${error.request}`,
      title: "ERROR",
    });
  } else {
    Toaster.pop({
      type: "error",
      message: `Fatal request ${error}`,
      title: "ERROR",
    });
  }
}
