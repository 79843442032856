import $ from "jquery";

export class FileUpload {
  private readonly $this: JQuery<HTMLElement>;
  private readonly $file: JQuery<HTMLElement>;
  private readonly $text: JQuery<HTMLElement>;
  private readonly $btn: JQuery<HTMLElement>;

  constructor() {
    this.$this = $(".js-file-upload");
    this.$file = this.$this.find(".js-file-upload--input-file");
    this.$text = this.$this.find(".js-file-upload--input-text");
    this.$btn = this.$this.find(".js-file-upload--button-submit");
    this.$text.on("click", () => this.onClickText());
  }

  private onClickText(): void {
    this.$file.on("change", () => this.onChangeFile());
    this.$file.trigger("click");
  }

  private onChangeFile(): void {
    const fileName =
      this.$file
        .val()
        ?.toString()
        .replace(/^.*[\\\/]/, "") ?? "";
    this.$text.val(fileName);
    if (fileName === null || /^\s*$/.test(fileName)) {
      this.$btn.prop("disabled", true);
    } else {
      this.$btn.prop("disabled", false);
    }
  }
}

new FileUpload();
