import $ from "jquery";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";

export default class Import {
  static init() {
    Import.trackSuccessItems();
  }

  static trackSuccessItems() {
    const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
    const $successList = $("#success-list-for-gtm");

    if ($successList.length === 0) {
      return;
    }

    const successListAsJson: string = $successList.val() as string;

    if (successListAsJson.length === 0) {
      return;
    }

    const successList = JSON.parse(successListAsJson);

    for (let successItem of successList) {
      successItem.DataLayerProduct.list_id = "import";
      successItem.DataLayerProduct.list_name = "Import";

      console.log("Calling natchGtm.trackAddToCart", successItem.DataLayerProduct, successItem.productTotalPrice);
      natchGtm.trackAddToCart(successItem.DataLayerProduct, successItem.productTotalPrice);
    }
  }
}
