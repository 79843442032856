﻿import $ from "jquery";

export default class ScrollToHandler {
  static init() {
    $("body").on("click", ".js-on-click-scroll-to", (e) => {
      const $clicked = $(e.currentTarget);
      const selector = $clicked.data("scroll-to-selector");
      const $scrollTo = $(selector);
      if ($scrollTo.length === 0) return;
      $("html").animate(
        {
          scrollTop: $scrollTo.offset()?.top,
        },
        {
          duration: 1000,
        }
      );
    });
  }
}

ScrollToHandler.init();
