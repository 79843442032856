import $ from "jquery";
import axios from "axios";
import select2 from "select2";
import { addDays, format, parse } from "date-fns";

import { TransferDateResponse } from "./models/basket";

// @ts-ignore
import datepicker from "js-datepicker";

enum GrandecoTransferType {
  Pickup = 1,
  Delivery = 2,
}

export default class BasketInformation {
  private static initialDeliveryAddressSetting = true;
  private static allowNewDeliveryAddress = true;
  private static invoiceAddressCountryId = 0;
  private static defaultTransferDate = new Date();
  private static selectedTransferDate = new Date();
  private static addresses: any = [];

  private static currentTransferType = GrandecoTransferType.Delivery;
  private static disabledDatesPickup: any = [];
  private static disabledDatesDelivery: any = [];
  private static datepickerDeliveryDate: any; // the component

  static init() {
    BasketInformation.setDeliveryAddresses();
    BasketInformation.setHolidays();

    BasketInformation.initHandleDeliveryAddress();
    BasketInformation.initHandleTransferTypeChange();
    BasketInformation.initFormSubmit();
  }

  static setDeliveryAddresses() {
    const addressList = $(".js-basket-info--delivery-address-list--hidden").val() as string;
    const invoiceCountryId = $(".js-basket-info--invoice-country-id--hidden").val() as number;

    if (typeof addressList === "undefined" || typeof invoiceCountryId === "undefined") {
      return;
    }

    BasketInformation.addresses = JSON.parse(addressList);

    const results = BasketInformation.addresses.filter((a: any) => {
      return a.id === 0;
    });

    if (results.length === 0) {
      BasketInformation.allowNewDeliveryAddress = false;
    }

    BasketInformation.handleDeliveryAddressSelected();
    BasketInformation.invoiceAddressCountryId = invoiceCountryId;
  }

  static setHolidays() {
    const holidayJson = $(".js-basket-info--holiday-list--hidden").val() as string;

    if (typeof holidayJson === "undefined") {
      return;
    }

    const holidayList = JSON.parse(holidayJson);

    for (let holiday of holidayList) {
      const date = BasketInformation.getDateFromString(holiday.date);
      if (holiday.transferTypeId === GrandecoTransferType.Pickup) {
        BasketInformation.disabledDatesPickup.push(date);
      } else if (holiday.transferTypeId === GrandecoTransferType.Delivery) {
        BasketInformation.disabledDatesDelivery.push(date);
      }
    }

    BasketInformation.initDatePicker();
    BasketInformation.updateTransferDate();
  }

  private static initHandleDeliveryAddress() {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");
    const $deliveryAddress = $delivery.find(".js-basket-info--delivery--address");

    BasketInformation.initSelect2($delivery.find("[name='DeliveryAddressId']"));
    $delivery.find("[name='DeliveryAddressId']").on("change", BasketInformation.handleDeliveryAddressSelected);

    $delivery
      .find("[name='DeliveryAddressIsInvoiceAddress']")
      .on("click", BasketInformation.handleDeliveryAddressIsInvoiceAddressClick);

    BasketInformation.handleDeliveryAddressIsInvoiceAddressClick(undefined);
    $deliveryAddress.find("[name='DeliveryAddress.CountryId']").prop("disabled", true);
  }

  private static initHandleTransferTypeChange() {
    const $form = $(".form-basket-information");

    $form.find(".js-basket-info--transfer-type").on("click", BasketInformation.handleTransferTypeChange);

    BasketInformation.handleTransferTypeChange(undefined);
  }

  private static initFormSubmit() {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");
    const $deliveryAddress = $delivery.find(".js-basket-info--delivery--address");

    $form.on("submit", (e: JQueryEventObject) => {
      $deliveryAddress.find("[name='DeliveryAddress.CountryId']").prop("disabled", false);
      $deliveryAddress.find(".js-basket-info--delivery--address-field").prop("disabled", false);
    });
  }

  private static getDisabledDatesForCurrentTransferType() {
    return BasketInformation.currentTransferType === GrandecoTransferType.Pickup
      ? BasketInformation.disabledDatesPickup
      : BasketInformation.disabledDatesDelivery;
  }

  private static initDatePicker() {
    const $datepickerDeliveryDate = $(".js-basket-info--delivery-date");
    const $datepickerDeliveryDateIcon = $(".js-basket-info--delivery-date-icon");

    if ($datepickerDeliveryDate.length === 0) {
      return;
    }

    // https://www.npmjs.com/package/js-datepicker
    const options = {
      startDay: 1,
      minDate: BasketInformation.getStartDate(),
      noWeekends: true,
      disableYearOverlay: true,
      disabledDates: BasketInformation.getDisabledDatesForCurrentTransferType(),
      formatter: (input: any, date: any, instance: any) => {
        input.value = format(date, "dd/MM/yyyy");
      },
      onSelect: (instance: any) => {
        console.log("datepicker instance onSelect");
        BasketInformation.selectedTransferDate = instance.dateSelected;
        BasketInformation.handleTransferDateChange();
      },
    };

    BasketInformation.datepickerDeliveryDate = datepicker(".js-basket-info--delivery-date", options);

    $datepickerDeliveryDate.off("change");
    $datepickerDeliveryDate.on("change", (e: JQueryEventObject) => {
      console.log("$datepickerDeliveryDate on change");
      const $datepicker = $(e.currentTarget);
      const dateValue = parse($datepicker.val() as string, "dd/MM/yyyy", new Date());

      const isEarlierDate = dateValue < BasketInformation.getStartDate();
      const isWeekend = dateValue.getDay() === 0 || dateValue.getDay() === 6;
      const isHoliday = BasketInformation.getDisabledDatesForCurrentTransferType()
        .map((h: Date) => h.getTime())
        .includes(dateValue.getTime());

      if (isEarlierDate || isWeekend || isHoliday) {
        $datepickerDeliveryDate.val("");
      } else {
        BasketInformation.setDatepickerDate(dateValue);
      }
    });

    $datepickerDeliveryDateIcon.on("click", (e: JQueryEventObject) => {
      e.stopPropagation();
      BasketInformation.datepickerDeliveryDate.show();
    });
  }

  private static initSelect2($target: JQuery<HTMLElement>) {
    // @ts-ignore
    select2($);

    // @ts-ignore
    $target.select2();
  }

  private static handleDeliveryAddressSelected(e: JQueryEventObject | undefined = undefined) {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");
    const $deliveryAddress = $delivery.find(".js-basket-info--delivery--address");

    const $select = $delivery.find("[name='DeliveryAddressId']");
    const deliveryAddressId = parseInt($select.val() as string);

    if (typeof e !== "undefined") {
      $deliveryAddress.find(".js-basket-info--delivery--address-field").prop("disabled", false);

      if (!BasketInformation.initialDeliveryAddressSetting) {
        $deliveryAddress.find(".js-basket-info--delivery--address-field").val("");
      }

      BasketInformation.initialDeliveryAddressSetting = false;
    }

    if (deliveryAddressId === 0) {
      $deliveryAddress.find(".js-basket-info--delivery--remember-address").show();
    } else {
      $deliveryAddress.find(".js-basket-info--delivery--remember-address").hide();
    }

    if (deliveryAddressId !== 0 && BasketInformation.addresses != null) {
      const results = BasketInformation.addresses.filter((a: any) => {
        return a.id === deliveryAddressId;
      });

      if (results.length === 1) {
        BasketInformation.populateDeliveryAddress(results[0]);
        $deliveryAddress.find(".js-basket-info--delivery--address-field").prop("disabled", true);
      } else {
        $deliveryAddress.find(".js-basket-info--delivery--address-field").val("");
      }
    } else {
      if (BasketInformation.invoiceAddressCountryId !== 0) {
        $deliveryAddress.find("[name='DeliveryAddress.CountryId']").val(BasketInformation.invoiceAddressCountryId);
        $deliveryAddress.find("[name='DeliveryAddress.CountryId']").prop("disabled", true);
      }
    }

    BasketInformation.updateTransferDate();
  }

  private static handleDeliveryAddressIsInvoiceAddressClick(e: JQueryEventObject | undefined = undefined) {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");
    const $deliveryAddress = $delivery.find(".js-basket-info--delivery--address");

    const $check = $delivery.find("[name='DeliveryAddressIsInvoiceAddress']");

    if ($check.is(":checked")) {
      $deliveryAddress.find(".js-basket-info--delivery--address-field").prop("disabled", true);
      $delivery.find("[name='DeliveryAddressId']").prop("disabled", true);
      $delivery.find("[name='DeliveryAddressId']").val("");

      $deliveryAddress.fadeOut();

      if (BasketInformation.addresses != null) {
        const results = BasketInformation.addresses.filter((a: any) => {
          return a.id === 0;
        });

        if (results.length === 0) {
          // nothing
        } else {
          $deliveryAddress.find(".js-basket-info--delivery--address-field").val("");
          BasketInformation.populateDeliveryAddress(results[0]);
        }
      }
    } else {
      $deliveryAddress.fadeIn();
      $deliveryAddress.find(".js-basket-info--delivery--address-field").prop("disabled", false);
      $delivery.find("[name='DeliveryAddressId']").prop("disabled", false);

      if (BasketInformation.allowNewDeliveryAddress === false) {
        $delivery.find("[name='DeliveryAddressId']").val(BasketInformation.addresses[0].id);
        BasketInformation.handleDeliveryAddressSelected(undefined);
      } else if (typeof e !== "undefined") {
        $delivery.find("[name='DeliveryAddressId']").val("0");
      }
    }

    $delivery.find("[name='DeliveryAddressId']").trigger("change");

    BasketInformation.updateTransferDate();
  }

  private static handleTransferTypeChange(e: JQueryEventObject | undefined = undefined) {
    const $form = $(".form-basket-information");

    const value = $form.find("[name='TransferType']:checked").val();

    const originalTransferTypeId = BasketInformation.currentTransferType;

    if (value === "Pickup") {
      BasketInformation.currentTransferType = GrandecoTransferType.Pickup;
      $form.find(".js-basket-info--delivery").show(); // show delivery fields / show 'destination' labels / hide 'shipping' labels
      $form.find(".js-basket-info--destination-address-toggle").show();
      $form.find(".js-basket-info--shipping-address-toggle").hide();
    } else {
      BasketInformation.currentTransferType = GrandecoTransferType.Delivery;
      $form.find(".js-basket-info--delivery").show(); // show delivery fields / hide 'destination' labels / show 'shipping' labels
      $form.find(".js-basket-info--destination-address-toggle").hide();
      $form.find(".js-basket-info--shipping-address-toggle").show();
    }

    if (originalTransferTypeId != BasketInformation.currentTransferType) {
      // update the disabled dates first
      BasketInformation.updateDisabledDates();
    }

    BasketInformation.updateTransferDate();
  }

  private static handleTransferDateChange() {
    const $form = $(".form-basket-information");
    const transferType = $form.find("[name='TransferType']:checked").val();

    const show =
      BasketInformation.selectedTransferDate < BasketInformation.defaultTransferDate && transferType !== "Pickup";

    $(".js-basket-info--delivery-date-warning").toggleClass("d-none", !show);
  }

  private static populateDeliveryAddress(address: any) {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");
    const $deliveryAddress = $delivery.find(".js-basket-info--delivery--address");

    $deliveryAddress.find(".js-basket-info--delivery--address-field").each((ix, el) => {
      const $input = $(el);
      const field = $input.data("field");

      if (field.indexOf("contact.") === 0) {
        const contact = address["contact"];

        if (contact != null) {
          $input.val(contact[field.substr(8)]);
        }
      } else {
        $input.val(address[field]);
      }
    });
  }

  private static updateDisabledDates() {
    if (!BasketInformation.datepickerDeliveryDate) {
      return;
    }
    // no option on the instance from what I can see, so we have to destroy and recreate
    console.debug("recreating datepicker");
    BasketInformation.datepickerDeliveryDate.remove();
    BasketInformation.initDatePicker();
  }

  private static updateTransferDate() {
    const $form = $(".form-basket-information");
    const $delivery = $form.find(".js-basket-info--delivery");

    if (BasketInformation.addresses == null) {
      return;
    }

    // get delivery address
    let deliveryAddressId = $delivery.find("[name='DeliveryAddressId']").val();

    if (deliveryAddressId == null || deliveryAddressId === "" || deliveryAddressId === 0) {
      if ($delivery.find("[name='DeliveryAddressIsInvoiceAddress']").is(":checked")) {
        deliveryAddressId = BasketInformation.getMainDeliveryAddressId();
      } else {
        deliveryAddressId = 0;
      }
    }

    // get transfer type
    const $transferType = $form.find("[name='TransferType']:checked");
    if ($transferType.length === 0) {
      return;
    }

    const transferType = $transferType.val();

    axios
      .get<TransferDateResponse>(`/api/basket/transferdate?transfertype=${transferType}&addressid=${deliveryAddressId}`)
      .then((res) => res.data)
      .then((data) => {
        const earliestTransferDate = BasketInformation.getDateFromString(data.earliestTransferDate);
        const earliestAllowedDate = BasketInformation.getStartDate();

        BasketInformation.defaultTransferDate = earliestTransferDate;
        const oldDate = BasketInformation.selectedTransferDate;

        if (!earliestAllowedDate) {
          return;
        }

        if (!oldDate) {
          BasketInformation.setDatepickerDate(BasketInformation.defaultTransferDate);
          BasketInformation.handleTransferDateChange();

          return;
        }

        oldDate.setHours(0, 0, 0, 0);
        earliestAllowedDate.setHours(0, 0, 0, 0);

        if (oldDate < earliestAllowedDate) {
          BasketInformation.setDatepickerDate(earliestTransferDate);
        }

        BasketInformation.handleTransferDateChange();
      })
      .catch((err) => console.error(err));
  }

  private static getMainDeliveryAddressId() {
    if (BasketInformation.addresses === null) {
      return 0;
    }

    let mainAddressId = 0;

    const mainAddress = BasketInformation.addresses.filter((a: any) => {
      return a.isMainShippingAddress;
    });

    if (mainAddress != null && mainAddress.length === 1) {
      mainAddressId = mainAddress[0].id;
    }

    return mainAddressId;
  }

  private static getStartDate() {
    let startDate = addDays(new Date(), 1);

    const now = new Date();
    if (now.getDay() === 0) {
      // Sunday
      startDate = addDays(new Date(), 2);
    } else if (now.getDay() === 5) {
      // Friday
      startDate = addDays(new Date(), 3);
    } else if (now.getDay() === 6) {
      // Saturday
      startDate = addDays(new Date(), 3);
    }

    return startDate;
  }

  private static getDateFromString(value: any): Date {
    if (typeof value === "undefined") {
      return new Date();
    }

    const parts = value.split(/\-|T/);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  private static setDatepickerDate(value: Date) {
    try {
      BasketInformation.selectedTransferDate = value;
      BasketInformation.datepickerDeliveryDate.setDate(value, true);
    } catch (error) {
      // do nothing (throws exception when disabled date is set)
    }
  }
}
