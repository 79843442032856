﻿import $ from "jquery";
import axios from "axios";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import * as Toaster from "./utils/toaster";
import Multilang from "./utils/multilang";
import MiniBasketData from "../composables/mini-basket-data";
import CustomSwiper from "./components/custom-swiper";
import { AddToBasketResponse } from "./models/basket";

export default class Site {
  static shortDateFormat: string;

  static init() {
    console.log("🚀 Site.init running");
    Site.initAutos();
    Site.initCookieNotice();
    Site.initLinkButtons();
    Site.initMobileTopNav();
    Site.initCompanyNav();
    Site.initQuickAdd();
    Site.initScrollToTop();
    Site.initSubmitForm();
    Site.initSubmitGuard();
    Site.initToggleNumber();
    Site.initToggleSubmit();
    Site.initLogOn();
    Site.initLogOff();
    Site.initCheckoutInputChange();

    CustomSwiper.init();
  }

  static showInfoToasts(messages: any) {
    if (messages !== null) {
      for (let msg of messages.split("<br/>")) {
        if (msg !== "") {
          Toaster.pop({
            type: "success",
            message: msg,
          });
        }
      }
    }
  }

  static showErrorToasts(messages: any) {
    if (messages !== null) {
      for (let msg of messages.split("<br/>")) {
        if (msg !== "") {
          Toaster.pop({
            type: "error",
            message: msg,
          });
        }
      }
    }
  }

  static showWarningToasts(messages: any) {
    if (messages !== null) {
      for (let msg of messages.split("<br/>")) {
        if (msg !== "") {
          Toaster.pop({
            type: "warning",
            message: msg,
          });
        }
      }
    }
  }

  static greet(name: string) {
    console.log(`Hello ${name}`);
  }

  private static initAutos() {
    $(".js-auto-submit").on("click", (e: JQueryEventObject) => $(e.currentTarget).parents("form").trigger("submit"));

    $(".js-auto-link").each((idx: number, el: Element) => {
      var a$ = $(el).find("a");
      if (a$.length !== 0) {
        $(el)
          .on("click", () => {
            a$.trigger("click");
          })
          .css("cursor", "pointer");
      }
    });

    $(".js-auto-signout").on("click", () => {
      $("#signout-form").trigger("submit");
    });

    if (($(window).width() || 0) > 991) {
      //$screen-md-min
      $(".js-onload-md-show").show();
    }
  }

  private static initCheckoutInputChange() {
    $(".js-register-input-change").on("keyup", (e: JQueryEventObject) => {
      $(e.currentTarget).addClass("js-dirty");
    });

    $("body").on("click", ".js-wait-for-change-handlers", (e: JQueryEventObject) => {
      if ($(".js-dirty").length > 0) {
        e.preventDefault();
        e.stopPropagation();
        $(".js-register-input-change").removeClass("js-dirty");
        return false;
      }

      return true;
    });
  }

  private static initCookieNotice() {
    $(".c-cookie-notice").on("click", ".btn", (e: JQueryEventObject) => {
      $(e.delegateTarget).fadeOut(200);
      document.cookie = "cookies=1;expires=" + new Date(2050, 0, 1).toUTCString();
    });
  }

  private static initLinkButtons() {
    $(".js-link-button").on(
      "click",
      (e: JQueryEventObject) => (document.location.href = $(e.currentTarget).data("href"))
    );
  }

  private static initMobileTopNav() {
    const topNavCollapsible = document.getElementById("mobile-top-navigation");
    if (!topNavCollapsible) {
      return;
    }

    topNavCollapsible.addEventListener("show.bs.collapse", function (e: Event) {
      if (e === null || e.target === null) {
        return;
      }

      const targetId = (e.target as HTMLElement).id;

      if (targetId === "mobile-top-navigation") {
        Site.handleMobileTopNav(true);
      }

      if (targetId.includes("mobile-sub-navigation")) {
        Site.handleMobileSubNav(targetId);
      }
    });

    topNavCollapsible.addEventListener("hidden.bs.collapse", function (e: Event) {
      if (e === null || e.target === null) {
        return;
      }

      const targetId = (e.target as HTMLElement).id;

      if (targetId === "mobile-top-navigation") {
        Site.handleMobileTopNav(false);
      }

      if (targetId.includes("mobile-sub-navigation")) {
        Site.handleMobileSubNav(targetId);
      }
    });
  }

  private static handleMobileTopNav(show: boolean) {
    const $topNavContainer = $("#mobile-top-navigation").closest("nav");

    const $togglerIcon = $topNavContainer.find(".navbar-toggler").find("i");
    $togglerIcon.toggleClass("ph-list", !show).toggleClass("ph-x", show);

    const $logo = $topNavContainer.find(".js-mobile-top-navigation--logo");
    $logo.toggleClass("visually-hidden", show);

    const $languageNav = $topNavContainer.find(".js-mobile-top-navigation--language-nav");
    $languageNav.toggleClass("visually-hidden", !show);

    const $searchNav = $topNavContainer.find(".js-mobile-top-navigation--search");
    $searchNav.toggleClass("visually-hidden", show);
  }

  private static handleMobileSubNav(targetId: string) {
    const $subNav = $(`#${targetId}`);
    const $subNavLink = $subNav.prev("a");
    const $subNavToggle = $subNavLink.find(".ph-caret-up, .ph-caret-down");

    $subNavToggle.toggleClass("ph-caret-up ph-caret-down");
  }

  private static initCompanyNav() {
    const subNavCollapsible = document.getElementById("company-sub-navigation");
    if (!subNavCollapsible) {
      return;
    }

    subNavCollapsible.addEventListener("show.bs.collapse", function (e: Event) {
      $("header .nt-main-navigation ul .list-inline-item.active").css("border-color", "#fff");
    });

    subNavCollapsible.addEventListener("hidden.bs.collapse", function (e: Event) {
      $("header .nt-main-navigation ul .list-inline-item.active").css("border-color", "#131a1c"); // $nt-secondary-900
    });
  }

  private static initQuickAdd() {
    $(".js-quick-add--form").on("submit", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $form = $(e.currentTarget);
      const $fieldProductNumber = $form.find($form.data("field-product-number"));
      const $fieldProductQuantity = $form.find($form.data("field-product-quantity"));

      const productNumberValue = $fieldProductNumber.val() as string;
      const productQuantityValue = $fieldProductQuantity.val() as string;

      if (productNumberValue.length <= 2) {
        Toaster.pop({
          type: "error",
          message: Multilang.getTranslation("product.quickadd.failed", "No product found that matches the given code."),
        });

        return false;
      }

      axios
        .get<AddToBasketResponse>(`/api/basket/quick?pn=${productNumberValue}&pq=${productQuantityValue}`)
        .then((res) => res.data)
        .then((data) => {
          console.debug("Quick add response", data);
          if (data.success) {
            let message = Multilang.getTranslation(
              "product.quickadd.success",
              "Success. The product was added to your basket."
            );

            if (data.message !== null && data.message !== "") {
              message = data.message;
            }

            message = message.replace("{quantityadded}", data.addedQuantity.toString());
            Site.showInfoToasts(message);

            // deep dive logging is executed server side
            if (data.dataLayerProduct) {
              const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
              data.dataLayerProduct.list_id = "quick-order";
              data.dataLayerProduct.list_name = "Quick Order";
              console.log("Calling natchGtm.trackAddToCart", data.dataLayerProduct, data.totalAmount);
              natchGtm.trackAddToCart(data.dataLayerProduct, data.totalAmount);
            }

            if ($form.hasClass("js-refresh-page")) {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              MiniBasketData.set(data.miniBasket);
              $fieldProductNumber.val("");
              $fieldProductQuantity.val("");
            }
          } else {
            if (data.message !== null) {
              Site.showErrorToasts(data.message);
            } else {
              Toaster.pop({
                type: "error",
                message: Multilang.getTranslation(
                  "product.quickadd.failed",
                  "No product found that matches the given code."
                ),
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          $fieldProductNumber.trigger("focus");
        });

      return true;
    });
  }

  private static initScrollToTop() {
    // Dynamic scroll to top button
    $(window).on("scroll", (e: JQueryEventObject) => {
      if (($(e.currentTarget).scrollTop() || 0) > 1800) {
        $(".nt-move-to-top").fadeIn(200);
      } else {
        $(".nt-move-to-top").fadeOut(200);
      }
    });
  }

  private static initSubmitForm() {
    $(".js-submit-form").on("click", (e: JQueryEventObject) => {
      const self = $(e.currentTarget);
      const target = self.data("nt-submit-target");

      const $form = $(target);
      if ($form.length > 0) {
        $form.trigger("submit");
      }
    });
  }

  private static initSubmitGuard() {
    $(".js-submit-guard").on("click", (e: JQueryEventObject) => {
      const self = $(e.currentTarget);
      const guardSelector = self.data("guard");

      if ($(guardSelector).is(":checked") === false) {
        e.preventDefault();

        Toaster.pop({
          type: "warning",
          message: self.attr("title") as string,
        });

        return false;
      }

      return true;
    });
  }

  private static initToggleNumber() {
    const $body = $("body");

    $body.on("click", ".js-toggle-number--down", (e: JQueryEventObject) => {
      const $down = $(e.currentTarget);
      const $container = $down.closest(".js-toggle-number");
      let $value = $container.find(".js-toggle-number--value");
      if ($value.length === 0) {
        $value = $container.find(".js-toggle-number--value-nodefault");
      }

      Site.handleToggleNumber($value, -1);
    });

    $body.on("click", ".js-toggle-number--up", (e: JQueryEventObject) => {
      const $up = $(e.currentTarget);
      const $container = $up.closest(".js-toggle-number");
      let $value = $container.find(".js-toggle-number--value");
      if ($value.length === 0) {
        $value = $container.find(".js-toggle-number--value-nodefault");
      }

      Site.handleToggleNumber($value, 1);
    });

    $body.on("keyup", ".js-toggle-number--value", (e: JQueryEventObject) => {
      const $value = $(e.currentTarget);

      Site.handleToggleNumber($value, 0);
    });
    $body.on("keyup", ".js-toggle-number--value-nodefault", (e: JQueryEventObject) => {
      const $value = $(e.currentTarget);

      Site.handleToggleNumber($value, 0, null);
    });
  }

  private static handleToggleNumber($value: any, changeValue: number, defaultValue: number | null = 1) {
    let numberValue = parseInt($value.val() as string);
    if (isNaN(numberValue)) {
      if (defaultValue === null) {
        return;
      }

      numberValue = defaultValue;
    }

    numberValue = numberValue + changeValue;

    if (numberValue === 0) {
      numberValue = 1;
    }

    $value.val(numberValue);
    $value.trigger("change");
  }

  private static initToggleSubmit() {
    $(".js-toggle-submit").on("click", (e: JQueryEventObject) => {
      const box = $(e.currentTarget);
      const form = box.parents("form");

      if (box.is(":checked")) {
        form.find("[type=submit]").removeAttr("disabled");
        form.find("a.submit").removeAttr("disabled");
      } else {
        form.find("[type=submit]").attr("disabled", "disabled");
        form.find("a.submit").attr("disabled", "disabled");
      }
    });
  }

  private static initLogOn() {
    const $password = $(".js-log-on--password");

    $password.on("focus", (e: JQueryEventObject) => {
      const $toggle = $(".js-log-on--password-toggle");
      $toggle.toggleClass("nt-border--dark", false).toggleClass("nt-border--focus", true);
    });

    $password.on("focusout", (e: JQueryEventObject) => {
      const $toggle = $(".js-log-on--password-toggle");
      $toggle.toggleClass("nt-border--focus", false).toggleClass("nt-border--dark", true);
    });

    $(".js-log-on--password-toggle").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      const $toggle = $(e.currentTarget);
      const $password = $(".js-log-on--password");

      const showClearText = $toggle.find("i.ph-eye").length > 0;
      if (showClearText) {
        $toggle.find("i").toggleClass("ph-eye", false).toggleClass("ph-eye-slash", true);
        $password.prop("type", "input");
      } else {
        $toggle.find("i").toggleClass("ph-eye-slash", false).toggleClass("ph-eye", true);
        $password.prop("type", "password");
      }
    });
  }

  private static initLogOff() {
    $(".js-log-off--link").on("click", (e: JQueryEventObject) => {
      e.preventDefault();
      e.stopPropagation();

      $(".js-log-off--form").submit();
    });
  }
}
