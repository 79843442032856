import { createApp, defineAsyncComponent } from "vue";

/*
 * Place this on a page to render one of these components:
 * <div id="vue-hello-world" data-foo="bar"></div>
 */

console.log("🚀 mounting vue components");

const vueComponents: Array<{ load: () => any; elementId: string }> = [
  {
    load: () => import("./PasswordHibpChecker.vue"),
    elementId: "vue-password-hibp-checker",
  },
  {
    load: () => import("./PasswordStrengthIndicator.vue"),
    elementId: "vue-password-strength-indicator",
  },
];

for (let vueComponent of vueComponents) {
  const mountElement = document.getElementById(vueComponent.elementId);
  console.log(vueComponent.elementId, mountElement);
  if (mountElement === null) {
    continue;
  }

  const component = defineAsyncComponent(vueComponent.load);
  //console.log("vue mounting...", component.name, "into", mountElement);
  createApp(component, { ...mountElement.dataset }).mount("#" + vueComponent.elementId);
}

// these don't use element id, but class name
if (
  document.getElementsByClassName("js-vue--mini-basket").length ||
  document.getElementsByClassName("js-vue--mini-basket-mobile").length
) {
  const MiniBasket = defineAsyncComponent(() => import("./MiniBasket.vue"));
  createApp(MiniBasket).mount(".js-vue--mini-basket");
  createApp(MiniBasket).mount(".js-vue--mini-basket-mobile");
}

if (document.getElementsByClassName("js-vue--basket-totals").length) {
  const BasketTotals = defineAsyncComponent(() => import("./BasketTotals.vue"));
  createApp(BasketTotals).mount(".js-vue--basket-totals");
}
