import $ from "jquery";

export default class InspirationList {
  static init() {
    InspirationList.initFilter();
    InspirationList.initPager();
  }

  private static initFilter() {
    const $inspirationList = $(".nt-inspiration-list");

    $inspirationList.on("click", ".js-change-filter", (e) => {
      e.preventDefault();

      const newKey = $(e.currentTarget).data("key");
      const filterKeyRegex = /(filterkey=)[^\&]+/;

      let redirectUrl = window.location.href;

      if (redirectUrl.match(filterKeyRegex)) {
        redirectUrl = redirectUrl.replace(filterKeyRegex, "$1" + newKey);
      } else {
        const separator = redirectUrl.indexOf("?") !== -1 ? "&" : "?";
        redirectUrl = `${redirectUrl}${separator}filterkey=${newKey}`;
      }

      window.location.href = redirectUrl;
    });
  }

  private static initPager() {
    const $inspirationList = $(".nt-inspiration-list");

    $inspirationList.on("click", ".js-change-page", (e) => {
      e.preventDefault();

      const newIx = $(e.currentTarget).data("ix");
      const pageIndexRegex = /(pageindex=)[^\&]+/;

      let redirectUrl = window.location.href;

      if (redirectUrl.match(pageIndexRegex)) {
        redirectUrl = redirectUrl.replace(pageIndexRegex, "$1" + newIx);
      } else {
        const separator = redirectUrl.indexOf("?") !== -1 ? "&" : "?";
        redirectUrl = `${redirectUrl}${separator}pageindex=${newIx}`;
      }

      window.location.href = redirectUrl;
    });
  }
}
