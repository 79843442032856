import { reactive, ref } from "vue";
import { BasketTotalsResponse } from "../script/models/basket";

export type FeeAndDiscountLine = {
  price: number;
  description: string;
  currencySymbol: string;
};

export type QuoteDiscountLine = {
  selected: boolean;
  hasError: boolean;
  id: number;
  displayName: string;
  description: string;
  code: string;
  percentage: number | string | null;
  originalPercentage: number | null;
  maxPercentage: number;
};

export class BasketTotalsData {
  static subTotalDisplay = ref("");
  static grandTotalDisplay = ref("");
  static totalDiscountDisplay = ref("");
  static totalDiscount = ref(0.0);
  static currencySymbol = ref("");
  static basketType = ref("");
  static blockBasketSubmit = ref(false);

  static feeAndDiscountLines = reactive({ items: [] as FeeAndDiscountLine[] });
  static quoteDiscountLines = reactive({ items: [] as QuoteDiscountLine[] });

  static set(data: BasketTotalsResponse) {
    console.log(data);
    this.subTotalDisplay.value = data.subTotalDisplay;
    this.grandTotalDisplay.value = data.grandTotalDisplay;
    this.totalDiscountDisplay.value = data.totalDiscountDisplay;
    this.totalDiscount.value = data.totalDiscount;
    this.currencySymbol.value = data.currencySymbol;
    this.basketType.value = data.basketType;
    this.blockBasketSubmit.value = data.blockBasketSubmit;

    // clear lists
    this.quoteDiscountLines.items.splice(0, this.quoteDiscountLines.items.length);
    this.feeAndDiscountLines.items.splice(0, this.feeAndDiscountLines.items.length);

    // add fees and discounts
    for (let item of data.feeAndDiscountLines) {
      this.feeAndDiscountLines.items.push({
        price: item.price,
        description: item.description,
        currencySymbol: item.currencySymbol,
      });
    }

    // add quote discounts
    for (let item of data.quoteDiscountLines) {
      this.quoteDiscountLines.items.push({
        selected: item.selected,
        id: item.id,
        code: item.code,
        description: item.description,
        displayName: item.displayName,
        percentage: item.percentage,
        originalPercentage: item.percentage,
        maxPercentage: item.maxPercentage,
        hasError: false,
      });
    }
  }

  /*
  static addToLineCount(value: number) {
    this.lineCount.value = this.lineCount.value + value;
  }

  static addToProductList(imageUrl: string, productCode: string, quantity: number, unitPrice: number) {
    this.productList.items.push({
      imageUrl: imageUrl,
      productCode: productCode,
      quantity: quantity,
      unitPrice: unitPrice,
    });
  }*/
}
