type ActionUrl = {
  name: string;
  value: string;
};

export default class ActionUrls {
  private static UrlList: ActionUrl[] = [];

  static load() {
    const actionUrlJson = (document.querySelector(".js-action-urls") as HTMLInputElement).value;
    this.UrlList = JSON.parse(actionUrlJson);
  }

  static getUrl(name: string): string {
    const filteredUrlList = this.UrlList.filter((l) => l.name === name);
    if (filteredUrlList.length === 0) {
      return "";
    }

    return filteredUrlList[0].value;
  }
}
